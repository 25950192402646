import React, { useState, useContext } from 'react'
import { UserContext } from '../../Context/UserContext';

const LoginModal = ({ close, handleRegisterModal, handleForgotPwdModal }) => {

    const {loginUser,loggedInCheck} = useContext(UserContext);

    const [formData, setFormData] = useState({
        signin_username:'',
        signin_password:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        });
    }

    const submitForm = async (e) => {
        e.preventDefault();

        console.log(formData);

        const data = await loginUser(formData);

        if(data && 'message' in data && data.message != "") alert(data.message.replaceAll('\\n','\n'));

        if(data && 'success' in data && data.success == "1") {
            e.target.reset();
            await loggedInCheck();
            close();
            return;
        } 



    }

    return (
        <>
            <div className="modal-content register-content">
                {/* <div className="modal-header">
                    <h1 className="modal-title text-uppercase">
                        Add Appraiser
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div> */}
                <div className="modal-body pd-25-65">
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <div className="register-title">Please Log In or Register</div>
                    <div className="register-wrapper row">
                        <div className="register-left col-md-6 position-relative">
                            <form className="login-form" onSubmit={submitForm}>
                                <div className="register-form-title mb-3">Log In</div>
                                <div className="register-form-group form-group mb-3">
                                    <label>Username</label>
                                    <input type="text" className="form-control" name="signin_username"  autoComplete="new-password" required onChange={onChangeInput}/>
                                </div>
                                <div className="register-form-group form-group mb-3">
                                    <label>Password</label>
                                    <input type="password" className="form-control" name="signin_password"  autoComplete="new-password" required onChange={onChangeInput} />
                                </div>
                                <div className="login-btn mt-4">
                                    <button type="submit" className="green-btn w-100 lg-btn">Log In</button>
                                </div>
                                <div className="forgot-link">
                                    <a data-toggle="modal" onClick={handleForgotPwdModal}>FORGOT PASSWORD?</a>
                                </div>
                                <span className="or">OR</span>
                            </form>
                        </div>
                        <div className="register-right col-md-6">
                            <div className="register-form">
                                <div className="register-form-title mb-3">Register</div>
                                <div className="register-about">
                                    Registration is easy and takes less than 2 minutes! Sign Up for your account now.
                                </div>
                                <div className="register-btn mt-114">
                                    <button className="green-btn w-100 lg-btn" type='button' onClick={handleRegisterModal}>Register</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginModal