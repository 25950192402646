import React from 'react'
import { getImages, LazyImage } from '../../const'

const GrandOpeningComingSoon = () => {
    return (
        <>
            <section className='grand-opening-wrap'>
                <div className='container'>
                    <div className='grand-opening-flex d-flex align-items-center'>
                        <div className='go-left wow fadeInLeft' data-wow-delay="0.2s">
                            <LazyImage src={getImages('Grand-Open-ribbon.svg')} alt="" />
                        </div>
                        <div className='go-right'>
                            <div className='main-heading wow fadeInDown' data-wow-delay="0.2s">Grand Opening Coming Soon!</div>
                            <div className='sub-heading wow fadeInDown' data-wow-delay="0.4s">Sign up to stay up to date on our private Grand <br />Opening party. Must RSVP to attend.</div>
                            <div className='d-flex align-items-center mobile-app-form wow fadeInUp' data-wow-delay="0.6s">
                                <input type='text' className='form-control me-20' placeholder='Enter Email' />
                                <button className='green-btn' type='button'>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GrandOpeningComingSoon