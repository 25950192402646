import React, { useContext, useEffect, useState } from 'react'
import { getImages } from '../../Componment/const'
import Breadcrumb from '../../Componment/Frontend/Common/Breadcrumb'
import OwlCarousel from "react-owl-carousel";
import { Field, Form, Formik } from 'formik';
import ValidationError from '../../Componment/Errors/ValidationError';
import Fancybox from '../../Componment/Frontend/Common/Fancybox';
import { VehicleContext } from '../../Componment/Context/VehicleContext';
import { useLocation } from 'react-router-dom';

const Vdp = () => {
    const optionsVdp = {
        responsive: {
            0: {
                items: 2,
                margin: 10
            },
            600: {
                items: 2,
                margin: 20
            },
            768: {
                items: 3,
                margin: 25
            },
            1000: {
                items: 3,
                margin: 25
            }
        },
    };

    const queryParameters = new URLSearchParams(window.location.search);
    const vehicleVIN = queryParameters.get("vin");

    const { getVehicleData, vehiclesByVIN, numberFormatter, submitContactForm } = useContext(VehicleContext);

    const defaultVehicleData = {
        exterior_color: "Gray",
        interior_color: "Rosso Ferrari",
        mileage: 2201,
        options_msrp: "42135",
        post_settings: null,
        price: "329000",
        stockno: "1017",
        tol: 23,
        year: 2021,
        make: "Ferrari",
        model: "812 GTS",
        trim: "Base",
        vdp_hero_image: getImages('hero-img.webp'),
        vehicle_name: "2018 Ferrari 488 Spider",
        vin: "ZFF97CMA4M0261467",
        body_style: "2D Convertible",
        images: [getImages('vdp-thumb1.webp'), getImages('vdp-thumb2.webp'), getImages('vdp-thumb3.webp'), getImages('vdp-thumb4.webp'), getImages('vdp-thumb5.webp'), getImages('vdp-thumb6.webp')],
        gallery_images: [[getImages('vdp-thumb1.webp'), getImages('vdp-thumb2.webp')], [getImages('vdp-thumb3.webp'), getImages('vdp-thumb4.webp')], [getImages('vdp-thumb5.webp'), getImages('vdp-thumb6.webp')]]

    };

    const [vehicleData, setVehicleData] = useState(defaultVehicleData);

    const [vehicleExist, setVehicleExist] = useState(false);

    useEffect(() => {
        getVehicleData();
    }, [])

    useEffect(() => {
        var vehicle_data = vehicleVIN && vehiclesByVIN && vehicleVIN in vehiclesByVIN ? vehiclesByVIN[vehicleVIN] : defaultVehicleData;
        const split_index = Math.ceil((vehicle_data.images.length - 1) / 2);
        
        if(split_index > 0) {
            //var gallery_images = vehicle_data.images.slice(1).reduce(function (rows, key, index) {
            //    return (index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
            //}, []);
            console.log(vehicle_data.images.length-1,split_index);
            var gallery_images = vehicle_data.images.slice(1).reduce(function (rows, key, index) {
                console.log(index,split_index,rows);
                return (index < split_index || vehicle_data.images.length-1 <= 3 ? rows.push([key]) : rows[index - split_index].push(key)) && rows;
            }, []);
            console.log(split_index,gallery_images);
            vehicle_data.gallery_images = gallery_images;
        } else
            vehicle_data.gallery_images = vehicle_data.images.slice(1);
        console.log(vehicle_data);
        setVehicleData(vehicle_data);
        if (vehiclesByVIN) setVehicleExist(true);
    }, [vehicleVIN, vehiclesByVIN]);

    //  UseLocation
    const location = useLocation();
    useEffect(() => {
        // Function to scroll to the section
        const scrollToSection = () => {
            const hash = location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        // Scroll after a slight delay to ensure DOM updates are complete
        const timeoutId = setTimeout(scrollToSection, 2000);

        // Clean up the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, [vehicleData]);

    // Is Loaded
    const [isDataLoaded, setIsDataLoaded] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsDataLoaded(false);
        }, 2000);
    }, [vehicleData]);
    // useEffect(() => {
    //     var vehicle_data = vehicleVIN && vehiclesByVIN && vehicleVIN in vehiclesByVIN ? vehiclesByVIN[vehicleVIN] : defaultVehicleData;
    //     const split_index = Math.ceil((vehicle_data.images.length - 1) / 2);
        
    //     if(split_index > 0) {
    //         //var gallery_images = vehicle_data.images.slice(1).reduce(function (rows, key, index) {
    //         //    return (index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
    //         //}, []);
    //         console.log(vehicle_data.images.length-1,split_index);
    //         var gallery_images = vehicle_data.images.slice(1).reduce(function (rows, key, index) {
    //             console.log(index,split_index,rows);
    //             return (index < split_index || vehicle_data.images.length-1 <= 3 ? rows.push([key]) : rows[index - split_index].push(key)) && rows;
    //         }, []);
    //         console.log(split_index,gallery_images);
    //         vehicle_data.gallery_images = gallery_images;
    //     } else
    //         vehicle_data.gallery_images = vehicle_data.images.slice(1);
    //     console.log(vehicle_data);
    //     setVehicleData(vehicle_data);
    //     if (vehiclesByVIN) setVehicleExist(true);
    // }, [vehicleVIN, vehiclesByVIN]);

    return (vehicleExist &&
        <>
            {isDataLoaded ?
                <div className='py-5 my-5 text-center'>
                    <img src={getImages('gears_green.svg')} alt='gears' />
                </div> :
                <section className='vdp-main' style={{
                    backgroundImage: `#161616 url(${getImages('hero-bg.webp')}) no-repeat center top`,
                    backgroundSize: '100%'
                }}>
                    <section className='vdp-banner pt-5'>
                        <div className='container mxw-1366'>
                            <Breadcrumb marginBottom={'0'} />
                            <div className='d-flex vdp-banner-flex align-items-center'>
                                <div className='w-60 mt-3 wow fadeInLeft' data-wow-delay="0.2s">
                                    <img src={vehicleData.vdp_hero_image} alt='hero' />
                                </div>
                                <div className='w-40 ps-5 pe-4 wow fadeInRight' data-wow-delay="0.4s">
                                    <div className='d-flex align-items-center mb-4'>
                                        <div className='brand-logo'>
                                            <img src={getImages(`logos/${vehicleData.make}.webp`)} />
                                        </div>
                                        <div className='brand-name ps-3 text-uppercase'>
                                            {vehicleData.make}
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between vdp-banner-info'>
                                        <div>
                                            <div className='vdp-md-title mb-3'>{vehicleData.year} {vehicleData.make}</div>
                                            <div className='sm-title text-green mb-3'>{vehicleData.model} {vehicleData.trim}</div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='xs-title'>Miles: {numberFormatter(vehicleData.mileage)}</div>
                                                <div className='d-md-none'>
                                                    <div className='d-inline-flex align-items-center mb-3'>
                                                        <span className='xs-title pe-2'>Price</span>
                                                        <span className='vdp-md-title'>{numberFormatter(vehicleData.price, true)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <div className='d-inline-flex align-items-center mb-3 d-none d-md-block'>
                                                <span className='xs-title pe-2'>Price</span>
                                                <span className='vdp-md-title'>{numberFormatter(vehicleData.price, true)}</span>
                                            </div>
                                            <div>
                                                <button className='green-btn lg-btn'>Inquire</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='vdp-gallery-wrap'>
                        <div className='container mxw-1366'>
                            <div className='vdp-gallery-tab'>
                                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item wow zoomIn" data-wow-delay="0.1s" role="presentation">
                                        <button className="nav-link active" id="Exterior-tab" data-bs-toggle="tab" data-bs-target="#Exterior-tab-pane" type="button" role="tab" aria-controls="Exterior-tab-pane" aria-selected="true">Exterior</button>
                                    </li>
                                    <li className="nav-item wow zoomIn" data-wow-delay="0.2s" role="presentation">
                                        <button className="nav-link" id="Interior-tab" data-bs-toggle="tab" data-bs-target="#Interior-tab-pane" type="button" role="tab" aria-controls="Interior-tab-pane" aria-selected="false">Interior</button>
                                    </li>
                                    <li className="nav-item wow zoomIn" data-wow-delay="0.3s" role="presentation">
                                        <button className="nav-link" id="Wheels-tab" data-bs-toggle="tab" data-bs-target="#Wheels-tab-pane" type="button" role="tab" aria-controls="Wheels-tab-pane" aria-selected="false">Wheels</button>
                                    </li>
                                    <li className="nav-item wow zoomIn" data-wow-delay="0.4s" role="presentation">
                                        <button className="nav-link" id="Engine-tab" data-bs-toggle="tab" data-bs-target="#Engine-tab-pane" type="button" role="tab" aria-controls="Engine-tab-pane" aria-selected="false">Engine</button>
                                    </li>
                                </ul> */}
                                <div className="tab-content wow zoomIn" data-wow-delay="0.6s">
                                    <div className="tab-pane fade show active">
                                        <Fancybox
                                            options={{
                                                Carousel: {
                                                    infinite: false,
                                                },
                                            }}
                                        >
                                            <OwlCarousel
                                                className="owl-theme galler-slider common-arrow"
                                                loop={false}
                                                margin={25}
                                                items={4}
                                                dots={false}
                                                nav={true}
                                                {...optionsVdp}
                                            >
                                                {vehicleData.gallery_images.map((v, i) => {
                                                    return (
                                                        <div className='item' key={i}>
                                                            {v.map((w, j) => {
                                                                return (
                                                                    <div className='gallery-box mb-25' key={j}>
                                                                        <a data-fancybox="gallery" href={w} className='position-relative'>
                                                                            <img src={w} alt='thumb' />
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                            </OwlCarousel>
                                        </Fancybox>
                                    </div>
                                </div>
                            </div>
                            <div className='vdpa-block mt-5 row'>
                                <div className='col-md-4 order-2'>
                                    <div className='vdpa-box p-4 h-100'>
                                        <div className='md-title mb-3 wow fadeInUp' data-wow-delay="0.2s">Vehicle Info</div>
                                        <div className='vdpa-table'>
                                            <table className='w-100'>
                                                <tbody>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Stock</th>
                                                        <td>{vehicleData.stockno}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>VIN</th>
                                                        <td className="text-nowrap"><span className='copy_icon'></span> {vehicleData.vin}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Year</th>
                                                        <td>{vehicleData.year}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Make</th>
                                                        <td>{vehicleData.make}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Model</th>
                                                        <td>{vehicleData.model}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Mileage</th>
                                                        <td>{numberFormatter(vehicleData.mileage)}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Trim</th>
                                                        <td>{vehicleData.trim}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Model Number</th>
                                                        <td>-01</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Body Style</th>
                                                        <td>{vehicleData.body_style}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Transmission</th>
                                                        <td>7-Speed Manual</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Cylinders</th>
                                                        <td>12</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Fuel Type</th>
                                                        <td>Gasoline</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Exterior Color</th>
                                                        <td>{vehicleData.exterior_color}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Interior Color</th>
                                                        <td>{vehicleData.interior_color}</td>
                                                    </tr>
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>Window Sticker</th>
                                                        <td className='text-green'><span className='doc_icon'></span> View Sticker</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between mt-5 mb-3 wow fadeInUp' data-wow-delay="0.2s">
                                            <div>
                                                <img src={getImages('carfax.webp')} alt='carfax' />
                                            </div>
                                            <div className='text-end'>
                                                <button className='text-green trans-btn d-inline-block'>View Report</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8' id="aboutVdp">
                                    <div className='vdpa-box h-100'>
                                        <div className='main-heading text-uppercase mb-3 wow fadeInUp' data-wow-delay="0.2s">About this Vehicle</div>
                                        <p className='wow fadeInUp' data-wow-delay="0.4s" dangerouslySetInnerHTML={{ __html: vehicleData.description }}></p>
                                        <div>
                                            <div className='md-title fw-400 my-5 pt-5 pb-3 wow fadeInUp' data-wow-delay="0.6s">Got a question? <a href="#" className='text-green'>Call</a> or <a href="#" className='text-green'>Chat</a> live with a sales representative</div>
                                            <Formik
                                                // validationSchema={validationSchema}
                                                initialValues={{
                                                    full_name: '',
                                                    email: '',
                                                    phone_number: '',
                                                    comments: '',
                                                }}
                                                onSubmit={(values) => {
                                                    console.log(values);
                                                    submitContactForm(values);
                                                    alert("Thank you for your submission");
                                                }}
                                            >
                                                {({ values, setFieldValue, field, form }) => (
                                                    <Form className="custom-form" autoComplete="off">
                                                        <div className='row'>
                                                            <div className='col-md-6 wow fadeInUp' data-wow-delay="0.2s">
                                                                <div className='form-group'>
                                                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                                                        <div className='cs-label'>Full Name</div>
                                                                        <div className='cs-require'>* Required</div>
                                                                    </div>
                                                                    <Field
                                                                        type="text"
                                                                        name="full_name"
                                                                        className="form-control"
                                                                    />
                                                                    <ValidationError name="full_name" />
                                                                </div>
                                                                <div className='form-group'>
                                                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                                                        <div className='cs-label'>Email</div>
                                                                        <div className='cs-require'>* Required</div>
                                                                    </div>
                                                                    <Field
                                                                        type="text"
                                                                        name="email"
                                                                        className="form-control"
                                                                    />
                                                                    <ValidationError name="email" />
                                                                </div>
                                                                <div className='form-group'>
                                                                    <div className='cs-label mb-2'>Phone Number</div>
                                                                    <Field
                                                                        type="text"
                                                                        name="phone_number"
                                                                        className="form-control"
                                                                    />
                                                                    <ValidationError name="phone_number" />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6 wow fadeInUp' data-wow-delay="0.4s">
                                                                <div className='form-group'>
                                                                    <div className='cs-label mb-2'>Comments</div>
                                                                    <Field
                                                                        type="text"
                                                                        name="comments"
                                                                        className="form-control h-218"
                                                                    />
                                                                    <ValidationError name="comments" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center mt-5 justify-content-between about-action-flex wow fadeInUp' data-wow-delay="0.6s">
                                                            <div className='md-title fw-400'><span className='phone-icon me-2'></span> 800-123-4567</div>
                                                            <div>
                                                                <button className='green-btn lg-btn w-300'>Send</button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='vdp-video-block mt-40 wow zoomIn' data-wow-delay="0.3s">
                                <div>
                                    <img src={vehicleData.vdp_hero_image} alt='yt' />
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }
        </>
    )
}

export default Vdp