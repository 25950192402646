import React from 'react'
import ChatStandalone from '../Chat/ChatStandalone'

const ChatModal = ({close}) => {
    return (

        <div className="modal-content p-0">
            <div className='d-none d-lg-block'>
                <div className="modal-header p-3 chat-modal-header">
                    <h1 className="modal-title">
                        Chat
                    </h1>
                    <button className="sm-box-close mt-0" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
            </div>
            <div className="modal-body p-0">
               <ChatStandalone closeChatModal={close}/>
            </div>
        </div>
    )
}

export default ChatModal