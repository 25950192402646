import React, { useEffect, useRef, useState, useContext } from 'react'
import { getImages } from '../../Componment/const';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CommonForm from '../../Componment/Frontend/Common/CommonForm';
import { Link, useNavigate } from 'react-router-dom';
import { VehicleContext } from '../../Componment/Context/VehicleContext';
import ModalLayout from '../../Componment/Frontend/Common/ModalLayout';
import GrandOpeningModal from '../../Componment/Frontend/Common/GrandOpeningModal';
import FeaturedVehicles from '../../Componment/Frontend/Home/FeaturedVehicles';
import GrandOpeningComingSoon from '../../Componment/Frontend/Home/GrandOpeningComingSoon';
import JoinMallingList from '../../Componment/Frontend/Home/JoinMallingList';


const Home2 = () => {
    const navigate = useNavigate();
    const [showSlider, setShowSlider] = useState(false);

    const { vehicleData, getVehicleData, numberFormatter } = useContext(VehicleContext);

    const optionssl = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    arrows: false,
                    centerPadding: '60px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: '30px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: '30px',
                    slidesToShow: 1
                }
            }
        ]
    };

    useEffect(() => {
        const handleResize = () => {
            setShowSlider(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);

        // Call the handler immediately so the component starts with the correct state
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const [openMobAccordion, setOpenMobAccordion] = useState(1);
    useEffect(() => {
        const interval = setInterval(() => {
            setOpenMobAccordion(prev => (prev % 3) + 1); // Cycle through 1, 2, 3
        }, 5000);

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    const handleMobAccordion = (item) => {
        setOpenMobAccordion(item)
    }
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.autoplay = true;
            videoRef.current.muted = true;
            videoRef.current.loop = false;
            videoRef.current.controls = false;
            // videoRef.current.play();
        }
    }, []);

    const [isMobileView, setIsMobileView] = useState(false);
    const updateView = () => {
        setIsMobileView(window.innerWidth <= 991);
    };
    useEffect(() => {
        updateView();
        window.addEventListener('resize', updateView);

        return () => {
            window.removeEventListener('resize', updateView);
        };
    }, []);

    useEffect(() => {
        getVehicleData();
    }, []);

    const [featuredList,setFeaturedList] = useState([]);

    useEffect(() => {
        if(vehicleData.length > 0) {
            setFeaturedList(vehicleData.sort((a,b) => b.price - a.price).slice(0,4))
            setShowSlider(window.innerWidth > 768);
        }        
    }, [vehicleData]);
    // Grand Opening Modal
    
    const [openGrandOpening, setOpenGrandOpening] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setOpenGrandOpening(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);
    const closeGrandOpening = () => {
        setOpenGrandOpening(false)
    }
    useEffect(() => {
        if (openGrandOpening) {
            setFadeIn(true);
        }
    }, [openGrandOpening]);

    const handleJoinMailingList = () => {
        setOpenGrandOpening(false)
        setTimeout(() => {
            const id = 'join-mailing-form';
            document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
    }
    // FadeIn video
    useEffect(() => {
        const videoElement = videoRef.current;
        const handleVideoEnd = () => {
            videoElement.classList.add('video-fade-in');
            videoElement.play();
            setTimeout(() => {
                videoElement.classList.remove('video-fade-in');
            }, 3000);
        };
        videoElement.addEventListener('ended', handleVideoEnd);

        return () => {
            videoElement.removeEventListener('ended', handleVideoEnd);
        };
    }, []);

    const handleRemoveUrlId = (e) => {
        e.preventDefault();
        const targetId = e.currentTarget.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            window.history.replaceState(null, null, ' ');
        }
    };
    return (
        <>
            <section className='banner-wrap home-banner 2-banner-wrap position-relative py-0'>
                <div className='video-relative'>
                    <video className='video-background' width="100%" ref={videoRef} muted loop playsInline autoPlay preload="auto">
                        <source src={`${getImages('banner_video.mp4')}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='home-overlay-pos'>
                    <div className='home-banner-pos'>
                        <div className='container'>
                            <div className='d-flex align-items-center banner-flex'>
                                <div className='w-55 position-relative h-225'>
                                    <div className='d-flex align-items-center'>
                                        <div className='m-title m-title1 wow fadeInLeft' data-wow-delay="0.4s">
                                            <img src={getImages('m-title1.webp')} alt='' />
                                        </div>
                                        <div className='m-title m-title2 wow fadeInLeft' data-wow-delay="0.8s">
                                            <img src={getImages('m-title2.webp')} alt='' />
                                        </div>
                                        <div className='m-title m-title3 wow fadeInLeft' data-wow-delay="1.2s">
                                            <img src={getImages('m-title3.webp')} alt='' />
                                        </div>
                                    </div>
                                    <div className='m-title m-title4 wow brookLinAnimation' data-wow-delay="2.6s">
                                        <img src={getImages('m-title4.webp')} alt='' />
                                    </div>
                                </div>
                                <div className={`w-45 ${isMobileView ? 'wow zoomIn' : ''}`} {...(!isMobileView && { 'data-wow-delay': '1.2s' })}>
                                    <div className={`banner-box wow fadeInRight ${openMobAccordion === 1 ? "active" : ""}`} data-wow-delay="0.4s">
                                        <div className='d-flex align-items-center' onClick={() => handleMobAccordion(1)}>
                                            <div className='bb-icon'>
                                                <img src={getImages('icon-sales.svg')} alt='icon' />
                                            </div>
                                            <div className='bb-content ps-4'>
                                                <div className='bb-title mb-2'>Sales</div>
                                                <p>We only stock pristine performance vehicles. Absolutely no stories with an unmatched buying experience. <a href="#salesDiv"  onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></p>
                                            </div>
                                        </div>
                                        <div className={`mob-bb-content ${openMobAccordion === 1 ? "" : "d-none"}`}>We only stock pristine performance vehicles. Absolutely no stories with an unmatched buying experience. <a href="#salesDiv"  onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></div>
                                    </div>
                                    <div className={`banner-box wow fadeInRight ${openMobAccordion === 2 ? "active" : ""}`} data-wow-delay="0.6s">
                                        <div className='d-flex align-items-center' onClick={() => handleMobAccordion(2)}>
                                            <div className='bb-icon'>
                                                <img src={getImages('icon-service.svg')} alt='icon' />
                                            </div>
                                            <div className='bb-content ps-4'>
                                                <div className='bb-title mb-2'>Service</div>
                                                <p>We favor excellence over profit margins. Our technicians are impressively versed at servicing the best vehicles in the world. <a href="#serviceDiv"  onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></p>
                                            </div>
                                        </div>
                                        <div className={`mob-bb-content ${openMobAccordion === 2 ? "" : "d-none"}`}>We favor excellence over profit margins. Our technicians are impressively versed at servicing the best vehicles in the world. <a href="#serviceDiv"  onClick={handleRemoveUrlId} className='text-green h-read-more'>Read more</a></div>
                                    </div>
                                    <div className={`banner-box wow fadeInRight ${openMobAccordion === 3 ? "active" : ""}`} data-wow-delay="0.8s">
                                        <div className='d-flex align-items-center' onClick={() => handleMobAccordion(3)}>
                                            <div className='bb-icon'>
                                                <img src={getImages('Community-icon.svg')} alt='icon' />
                                            </div>
                                            <div className='bb-content ps-4'>
                                                <div className='bb-title mb-2'>Who We Are</div>
                                                <p>No drama, no jargon. Where car lovers come together for life.<br /> <Link to="/about" className='text-green h-read-more'>Read more</Link></p>
                                            </div>
                                        </div>
                                        <div className={`mob-bb-content ${openMobAccordion === 3 ? "" : "d-none"}`}>No drama, no jargon. Where car lovers come together for life. <Link to="/about" className='text-green h-read-more'>Read more</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='featured-vehicles-wrap pb-0 pt-5'>
                <div className='container'>
                    <div className='featured-vehicles-list row'>
                        <div className='col-md-6 mt-44 wow fadeInLeft' data-wow-delay="0.2s" id='salesDiv'>
                            <div className='fv-box'>
                                <div className='fvb-top'>
                                    <img src={getImages('sales-img.webp')} alt='car' />
                                </div>
                                <div className='fvb-bottom'>
                                    <div className='main-heading mb-2'>Sales</div>
                                    <p className='text-green'>We only stock the world’s best cars. Absolutely no stories.</p>
                                    <ul className='fvb-list'>
                                        <li>Immaculate inventory of pre-owned dream cars</li>
                                        <li>Consignment veterans, we know how to market and sell your car</li>
                                        <li>Aggressive financing options</li>
                                        <li>Nationwide Enclosed Delivery</li>
                                        <li>A proud division of the Experience Auto Group</li>
                                    </ul>
                                    <div className="text-center">
                                        <button className="green-btn w-330 md-btn">View Collection</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-44 wow fadeInRight' data-wow-delay="0.4s" id='serviceDiv'>
                            <div className='fv-box'>
                                <div className='fvb-top'>
                                    <img src={getImages('service-img.webp')} alt='car' />
                                </div>
                                <div className='fvb-bottom'>
                                    <div className='main-heading mb-2'>Service</div>
                                    <p className='text-green'>Insane acumen paired with crazy attention to detail served over a bed of world class customer service. </p>
                                    <ul className='fvb-list'>
                                        <li>Ferrari factory trained technicians and staff</li>
                                        <li>Only Factory OE parts</li>
                                        <li>Flat-bed pickup and delivery</li>
                                        <li>Unmatched Pricing. Low expense structure passes along value that cannot be matched</li>
                                    </ul>
                                    <div className="text-center">
                                        <button className="green-btn w-330 md-btn">Schedule Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FeaturedVehicles/>
            <GrandOpeningComingSoon/>

            <JoinMallingList/>
            {/* {openGrandOpening &&
                <ModalLayout open={openGrandOpening} close={closeGrandOpening}  darkThemeCls='true' grandOpening="true" fadeIn={fadeIn}  modalWidth={800}>
                    <GrandOpeningModal close={closeGrandOpening} handleJoinMailingList={handleJoinMailingList}/>
                </ModalLayout>
            } */}
        </>
    )
}

export default Home2