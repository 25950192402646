import { useState } from 'react';
import { getImages } from '../../const';

const ChatMessageField = ({ uploadFile, sendMessage, isLoading }) => {

    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    return (
        <>
            <div className="send-box">
                <input
                    type="text"
                    className="form-control"
                    aria-label="message…"
                    placeholder="Write message…"
                    value={message}
                    onChange={handleInputChange}
                    onKeyPress={(e) => { if (e.key === 'Enter') { sendMessage(message); setMessage(""); } }}
                />
                <div className="button-wrapper">
                    {isLoading ?
                        <div className="spinner-border text-light" role="status" style={{ width: "1.2rem", height: "1.2rem" }}>
                            <span className="visually-hidden">Loading...</span>
                        </div> : 
                        <>
                            <span className="label">
                                <img className="img-fluid" src={getImages('icon-attach.svg')} alt="image title" />
                            </span>
                            <input type="file" name="upload" onClick={(e)=>e.target.value = null} id="upload" className="upload-box" onChange={(e)=>uploadFile(e.target.files)} placeholder="Upload File" aria-label="Upload File" />
                        </>
                    }
                </div>
                <button type="button" className="chat-theme-btn" onClick={() => {sendMessage(message);  setMessage("");}}>
                    Send
                </button>
            </div>
        </>
    )
}

export default ChatMessageField