import React from 'react'

const ChatLayout = ({children}) => {
  return (
    <>
        <section className="message-area chat-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="chat-area">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ChatLayout