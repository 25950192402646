import React from 'react'
import { getImages, LazyImage } from '../../const'
import { Link } from 'react-router-dom'

const HomeSalesInside = () => {
    return (
        <>
            <div className="panel-inner panel-inside-bg d-flex align-items-center">
                <div className='w-50'>
                    <div className='mxw-470 mx-auto'>
                        <div className="xxl-title text-start">Sales</div>
                        <p className='text-green'>We only stock the world’s best cars. Absolutely no stories.</p>
                        <ul className='fvb-list text-start'>
                            <li>Immaculate inventory of pre-owned dream cars</li>
                            <li>Consignment veterans, we know how to market and sell your car</li>
                            <li>Aggressive financing options</li>
                            <li>Nationwide Enclosed Delivery</li>
                            <li>A proud division of the Experience Auto Group</li>
                        </ul>
                        <div>
                            <Link to="/srp?dealer_inventory" className='green-btn xl-btn d-inline-block'>View Collection</Link>
                        </div>
                    </div>
                </div>
                <div className='w-50 img-100vh d-none d-md-block'>
                    <LazyImage src={getImages('sales-inside-img.webp')} alt="" />
                </div>
            </div>
        </>
    )
}

export default HomeSalesInside