import React, { useState } from 'react'

const MarketAnalysis = ({ close }) => {
    return (
        <>
            <div className="modal-content market-analysis-modal">
                <div className="modal-header">
                    <h1 className="modal-title text-uppercase">
                        MARKET ANALYSIS
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body px-4 py-5">
                    <div className='custom-radio d-inline-block region-tag'>
                        <input type="radio" id="region_radio" name="radio-group" checked onChange={(e) => {console.log(e);}}/>
                        <label htmlFor="region_radio">REGION</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketAnalysis