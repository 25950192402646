import React from 'react'

const Breadcrumb = ({marginBottom, clsName}) => {
    return (
        <>
            <nav className={`custom-breadcrumb ${marginBottom === "0" ? 'mb-0' : 'mb-5'}${clsName ? "" : "ps-4" }`} aria-label="breadcrumb">
                <ol className={`breadcrumb ${clsName ? "mb-0" : "" }`}>
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item"><a href="#">Collections</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Ferrari</li>
                </ol>
            </nav>
        </>
    )
}

export default Breadcrumb