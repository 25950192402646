import React from 'react'
import { getImages, LazyBackground } from '../../const'
import CommonForm from '../Common/CommonForm'

const JoinMallingList = () => {
    return (
        <>
            <LazyBackground src={getImages('become-member.webp')} className="lazy-bg drop-line-wrap join-mailing-wrap">
                {/* <section className='drop-line-wrap join-mailing-wrap'
                style={{
                    backgroundImage: `url(${getImages('become-member.webp')}) no-repeat center center`,
                    backgroundSize: 'cover'
                }}
            > */}
                <div className='container mxw-980' id="join-mailing-form">
                    <div className='main-heading mb-3 text-uppercase text-center wow fadeInDown' data-wow-delay="0.2s">Join Our Mailing List</div>
                    <p className='text-center font-1-3em text-white wow fadeInDown' data-wow-delay="0.4s">Stay up to date on the latest events & inventory drops.</p>
                    <CommonForm />
                </div>
                {/* </section> */}
            </LazyBackground>
        </>
    )
}

export default JoinMallingList