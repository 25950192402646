import React from 'react'
import { getImages } from '../../const'

const AboutReadMoreModal = ({close, meetTeamDtl}) => {
  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title filter-modal-title">
            {meetTeamDtl.name}
          </h1>
          <button className="sm-box-close" type="button" onClick={close}>
            <img src={getImages('white-close.svg')} />
          </button>
        </div>
        <div className="modal-body p-5 text-white">
          {meetTeamDtl.msg}
        </div>
      </div>
    </>
  )
}

export default AboutReadMoreModal