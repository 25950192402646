import {createContext, useEffect, useState} from 'react';
import axios from 'axios'

export const VehicleContext = createContext();

export const VehicleContextProvider = ({children}) => {
    const baseURL = window.location.host == "kentmotorclub.com" ? "https://kentmotorclub.com/" : "http://dev.kentmotorclub.com/";

    const Axios = axios.create({ baseURL: baseURL });

    const [vehicleData, setVehicleData] = useState(false);
    const [filteredVehicleData, setFilteredVehicleData] = useState(false);
    const [vehiclesByVIN, setVehiclesByVIN] = useState(false);

    const [searchText,setSearchText] = useState("");

    const [vehicleYears,setVehicleYears] = useState([]);
    const [vehicleMakes,setVehicleMakes] = useState([]);
    const [vehicleModels,setVehicleModels] = useState([]);
    const [vehicleTrims,setVehicleTrims] = useState([]);

    const getVehicleData = async () => {
        //setOpenLoadingModal(true);
        try {
            //const loginToken = localStorage.getItem('loginToken');
            //Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            var values = {
                "dealership_id" : "gc",
                "sort_inventory" : true
            };	

            const response = await Axios.get('bridge/inventory/inventory.php',{
                retry: 0, retryDelay: 3000, params: values
            });

            var vehicles = [];
            var vehicles_by_vin = [];

            var found_lastest_drop = false;
            var latest_drop;

            var vehicle_years = {};
            var vehicle_makes = {};
            var vehicle_models = {};
            var vehicle_trims = {};

            response.data.map((v,i) => {
                if(v.image_urls) {
                    v.images = v.image_urls.replaceAll('\"','').replaceAll('"','').split(",");

                    v.vdp_hero_image = "https://s3-us-west-2.amazonaws.com/ethosautos/vdp/" + v.vin + ".gif";

                    if(!found_lastest_drop && v['latest_drop']) {
                        v.LatestDrop = true;
                        latest_drop = v;
                        found_lastest_drop = true;
                    } else {
                        vehicles.push(v);
                        vehicles_by_vin[v.vin] = v;
                    }

                    if(latest_drop && i%3 == 0 && i != 0) {
                        vehicles.push(latest_drop);
                        vehicles_by_vin[latest_drop.vin] = latest_drop;
                        latest_drop = null;
                    }                     
                }

                if(v.year in vehicle_years) vehicle_years[v.year]['available']++;
                else vehicle_years[v.year] = { name: v.year, available: 1, isSelected: false };

                if(v.make in vehicle_makes) vehicle_makes[v.make]['available']++;
                else vehicle_makes[v.make] = { name: v.make, available: 1, isSelected: false };

                if(v.model in vehicle_models) vehicle_models[v.model]['available']++;
                else vehicle_models[v.model] = { name: v.model, available: 1, isSelected: false };

                if(v.trim in vehicle_trims) vehicle_trims[v.trim]['available']++;
                else vehicle_trims[v.trim] = { name: v.trim, available: 1, isSelected: false };
            });

            console.log(vehicles,vehicles_by_vin);
            setVehicleData(vehicles);
            setFilteredVehicleData(vehicles);
            setVehiclesByVIN(vehicles_by_vin);

            setVehicleYears(Object.keys(vehicle_years).map(key => vehicle_years[key]).sort((b,a) => a.name - b.name));
            setVehicleMakes(Object.keys(vehicle_makes).map(key => vehicle_makes[key]).sort((b,a) => b.name > a.name ? 1 : (a.name > b.name ? -1 : 0)));
            setVehicleModels(Object.keys(vehicle_models).map(key => vehicle_models[key]).sort((b,a) => b.name > a.name ? 1 : (a.name > b.name ? -1 : 0)));
            setVehicleTrims(Object.keys(vehicle_trims).map(key => vehicle_trims[key]).sort((b,a) => b.name > a.name ? 1 : (a.name > b.name ? -1 : 0)));

        } catch (error) {
            console.error('Error fetching vehicles:', error);
            //setOpenLoadingModal(false);
            return {}; // Or handle error as needed
        }
    };

    const filterVehicleData = (vehicleData) => {
        var filteredResults = vehicleData;

        if (searchText) {
            filteredResults = vehicleData.filter(vehicle =>
                (vehicle.vehicle_name && vehicle.vehicle_name.toLowerCase().includes(searchText)) ||
                (vehicle.vin && vehicle.vin.toLowerCase().includes(searchText.toLowerCase()))
            );
        }

        filteredResults = filterByField(filteredResults,vehicleYears,'year');

        filteredResults = filterByField(filteredResults,vehicleMakes,'make');

        filteredResults = filterByField(filteredResults,vehicleModels,'model');

        filteredResults = filterByField(filteredResults,vehicleTrims,'trim');

        return filteredResults;
    }

    const filterByField = (current_data,filter_data,field) => {
        var current_filter = [];

        current_filter = filter_data.filter((v) => v.isSelected).map(a => a.name);

        if(current_data && current_filter.length > 0) {

            current_data = current_data.filter(vehicle =>
                current_filter.includes(vehicle[field])
            );

        }

        //console.log(current_data,current_filter);

        return current_data;
    }


    const numberFormatter = (value, currency = false) => {
        var num = value ? value.toString().replace(/[^0-9\.]+/g,"") : 0;
        var thousands = num >= 1000;
    
        if(thousands) num = Math.round(num / 100) / 10;
        
        var sign = num >= 0 ? "" : "-";
        var str = num.toString().replace("$", ""), parts = false, output = [], i = 1, formatted = null;
        if(str.indexOf(".") > 0) {
            parts = str.split(".");
            str = parts[0];
        }
        str = str.split("").reverse();
        for(var j = 0, len = str.length; j < len; j++) {
            if(str[j] != ",") {
                output.push(str[j]);
                if(i%3 == 0 && j < (len - 1)) {
                    output.push(",");
                }
                i++;
            }
        }
        formatted = output.reverse().join("");
        return((currency ? "$" : "") + sign + formatted + ((parts) ? "." + parts[1].substr(0, 2) : "") + (thousands ? "k" : ""));
        // + (thousands ? "k" : "")
    }
    const priceFormatter = (value, currency = false) => {
        var num = value ? value.toString().replace(/[^0-9\.]+/g,"") : 0;
        
        var sign = num >= 0 ? "" : "-";
        var str = num.toString().replace("$", ""), parts = false, output = [], i = 1, formatted = null;
        if(str.indexOf(".") > 0) {
            parts = str.split(".");
            str = parts[0];
        }
        str = str.split("").reverse();
        for(var j = 0, len = str.length; j < len; j++) {
            if(str[j] != ",") {
                output.push(str[j]);
                if(i%3 == 0 && j < (len - 1)) {
                    output.push(",");
                }
                i++;
            }
        }
        formatted = output.reverse().join("");
        return((currency ? "$" : "") + sign + formatted + ((parts) ? "." + parts[1].substr(0, 2) : ""));
        // + (thousands ? "k" : "")
    }

    /*useEffect(() => {

        if(window.location.pathname.includes("srp") || window.location.pathname.includes("vdp")) getVehicleData();

        console.log("vehiclecontext useEffect",window.location.pathname);

    },[window.location.pathname]);*/

    const submitContactForm = async (values) => {
        const response = await Axios.post('bridge/contact/',values);
        console.log(response.data ? response.data : response);
    }

    useEffect(() => {        

        setFilteredVehicleData(filterVehicleData(vehicleData));

        //console.log("vehiclecontext useEffect searchtext",searchText);
        
    }, [searchText,vehicleYears,vehicleMakes,vehicleModels,vehicleTrims]);

    

    return (
        <>
            <VehicleContext.Provider value={{vehicleData,getVehicleData,numberFormatter, priceFormatter, filteredVehicleData,searchText,setSearchText,vehiclesByVIN,vehicleYears,vehicleMakes,vehicleModels,vehicleTrims,setVehicleYears,setVehicleMakes,setVehicleModels,setVehicleTrims,submitContactForm}}>
                {children}
            </VehicleContext.Provider>
        </>
    );
}

export default VehicleContextProvider