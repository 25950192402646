import React, { useState, useEffect } from 'react'

const Timer = ({time_to_expire,diff_seconds,timerLayout}) => {
    var timer_seconds;
    var countdownTimer;
    var intTime = new Date().getTime();

    const [timeRemaining,setTimeRemaining] = useState(time_to_expire);

    const [timeRemainingData,setTimeRemainingData] = useState(time_to_expire);

    const timer = () => {
        var intNow = new Date().getTime();
        if (intNow - intTime > 2000) {
            timer_seconds -= parseInt((intNow - intTime)/1000);
            //console.log("I JUST WOKE UP " + (intNow - intTime)/1000);
        }
        intTime = intNow;
            
        var days        = Math.floor(timer_seconds/24/60/60);
        var hoursLeft   = Math.floor((timer_seconds) - (days*86400));
        var hours       = Math.floor(hoursLeft/3600);
        var minutesLeft = Math.floor((hoursLeft) - (hours*3600));
        var minutes     = Math.floor(minutesLeft/60);
        var remainingSeconds = timer_seconds % 60;

        var totalHoursLeft = Math.floor(timer_seconds/3600);
        var totalMintuesLeft = Math.floor(timer_seconds/60);

        if (remainingSeconds < 10) {
            remainingSeconds = "0" + remainingSeconds; 
        }
        
        if (timer_seconds <= 1) {
            clearInterval(countdownTimer);
            setTimeRemaining("0Day 0h 0m");
            
            return;
        }
            
        //setTimeRemaining((days < 1 ? "" : days + "Day ") + hours + "h " + minutes + "m" + (days < 1 ? " " + remainingSeconds + "s" : ""));
        setTimeRemaining((days < 1 ? days + "Day " : days + "Days ") + hours + "h " + minutes + "m " + remainingSeconds + "s");
        setTimeRemainingData({ days, hours, minutes, remainingSeconds, hoursLeft, minutesLeft, totalHoursLeft, totalMintuesLeft });
        
        timer_seconds--;
        
        if(days == 0 && hours == 0 && timer_seconds == 0 && (minutes == 10 || minutes == 5 || minutes == 1))
            alert('This Auction has ' + minutes + ' minute' + (minutes > 1 ? 's' : '') + ' remaining');
    }

    useEffect(() => {
        if(diff_seconds <= 0) {
            //alert('Sorry this Auction has ended');
        } else {
            timer_seconds = diff_seconds - 1;
            
            //console.log('setting timer ' + timer_seconds);
            countdownTimer = setInterval(timer, 1000);
        }
    }, [diff_seconds]);
			


    return (
        timerLayout == "bidmodal" ? 
            <div className="live-auction-right w-50 d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                    <div className={`xs-title font-1-3em fw-700 text-start me-2 ${timeRemainingData.days < 4 ? 'text-red' : ''}`}>{timeRemainingData.days}</div>
                    <div className={`xs-title fw-300  ${timeRemainingData.days < 1 ? 'text-red' : ''}`}>Day{timeRemainingData.days > 1 ? 's' : ''}</div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className={`xs-title font-1-3em fw-700 text-start me-2 ${timeRemainingData.totalHoursLeft < 4 ? 'text-red' : ''}`}>{timeRemainingData.hours}</div>
                    <div className={`xs-title fw-300 ${timeRemainingData.totalHoursLeft < 4 ? 'text-red' : ''}`}>Hour{timeRemainingData.totalHoursLeft > 1 ? 's' : ''}</div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className={`xs-title font-1-3em fw-700 text-start me-2 ${timeRemainingData.totalMintuesLeft < 4 ? 'text-red' : ''}`}>{timeRemainingData.minutes}</div>
                    <div className={`xs-title fw-300  ${timeRemainingData.totalMintuesLeft < 4 ? 'text-red' : ''}`}>Min</div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className={`xs-title font-1-3em fw-700 text-start me-2 ${timeRemainingData.totalMintuesLeft < 4 ? 'text-red' : ''}`}>{timeRemainingData.remainingSeconds}</div>
                    <div className={`xs-title fw-300  ${timeRemainingData.totalMintuesLeft < 4 ? 'text-red' : ''}`}>Sec</div>
                </div>
            </div>
        : (timerLayout == "footer" ?
            <>{timeRemainingData.days} Day{timeRemainingData.days > 1 ? 's' : ''} <span className='text-green'>{timeRemainingData.hours}hr{timeRemainingData.hours > 1 ? 's' : ''} {timeRemainingData.minutes}m</span></>
        :
        (timerLayout == "srp" ? 
            <div className='xs-title font-1-1em fw-700'>
                <span className='clock-icon me-3'></span> 
                <span className={`${timeRemainingData.days < 4 ? 'text-red' : ''}`}>{timeRemainingData.days}d</span>&nbsp;
                <span className={`${timeRemainingData.totalHoursLeft < 4 ? 'text-red' : ''}`}>{timeRemainingData.hours}h</span>&nbsp;
                <span className={`${timeRemainingData.totalMintuesLeft < 4 ? 'text-red' : ''}`}>{timeRemainingData.minutes}m</span>
            </div>
            :
            <div>{timeRemaining}</div>
        ))



    )
}

export default Timer