import React from 'react'
import { getImages } from '../../Componment/const'
import { Field, Form, Formik } from 'formik';
import ValidationError from '../../Componment/Errors/ValidationError';
import CommonForm from '../../Componment/Frontend/Common/CommonForm';

const OurCommunity = () => {
    return (
        <>
            <section className='banner-wrap community-banner-wrap' style={{ background: `url(${getImages('community_hero_banner.webp')}) no-repeat center center` }}>
                <div className='container'>
                    <div className='d-flex align-items-center banner-flex'>
                        <div className='w-100'>
                            <div className='banner-title text-center pb-0 wow zoomIn' data-wow-delay="0.5s">Our Community</div>
                            <div className='md-title text-center text-uppercase font-1-5em mt-3 wow zoomIn' data-wow-delay="1s">Kent Club Motor Club</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='com-commmon-wrap'>
                <div className='container'>
                    <div className='com-common-box d-flex align-items-center'>
                        <div className='w-50 pe-5 wow fadeInLeft' data-wow-delay="1s">
                            <div className='lg-title mb-4'>WHAT WE’RE ABOUT</div>
                            <div className='content'>
                                <p>Kent Motor Club is about two things, cars and community. Where horsepower intersects with high society, our exotic car club is not just a community; it’s an elite sanctuary for the true gear head connoisseur. </p>
                            </div>
                        </div>
                        <div className='w-50 cc-img text-end wow fadeInRight' data-wow-delay="1.5s">
                            <img src={getImages('community-img1.webp')} alt='community'/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='horsepower-wrap'>
                <div className='horsepower-block pm-title d-flex align-items-start justify-content-between wow fadeInUp' data-wow-delay="0.4s">
                    <img src={getImages('quote-left.svg')} />
                    <div className='pt-3'>Where <span className='text-green'>horsepower</span> intersects <br/>with belonging</div>
                    <img src={getImages('quote-right.svg')} />
                </div>
            </section>
            <section className='com-commmon-wrap'>
                <div className='container'>
                    <div className='com-common-box d-flex align-items-center'>
                        <div className='w-50 cc-img text-start wow fadeInLeft' data-wow-delay="0.5s">
                            <img src={getImages('community-img2.webp')} alt='community'/>
                        </div>
                        <div className='w-50 ps-5 wow fadeInRight' data-wow-delay="1s">
                            <div className='lg-title mb-4'>OUR MEMBERS</div>
                            <div className='content'>
                                <p>With only 50 coveted VIP storage spaces, your treasured vehicle will reside among the rarest of its kind, attended by expert hands and admiring eyes. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='community-content'>
                <div className='container'>
                    <div className='content wow fadeInUp' data-wow-delay="0.2s">
                        <p>As a social member, you'll gain access to our incredible members lounge, The Humidor, exclusive service discounts, event space priority and entry to our incredible quarterly gatherings. Apply now to join our ranks—because luxury loves company, and here, we drive in style.</p>
                    </div>
                    <div className='text-center mt-5 wow fadeInUp' data-wow-delay="0.4s">
                        <button className='green-btn lg-btn mob-lg-btn w-330'>Apply Now</button>
                    </div>
                </div>
            </section>
            <section className='membership-perks-wrap community-membership-wrap'>
                <div className='container'>
                    <div className='main-heading mb-4 wow fadeInDown' data-wow-delay="0.1s">Membership Perks</div>
                    <div className='d-flex mp-flex justify-content-between gap-2'>
                        <div className='mp-col wow fadeInUp' data-wow-delay="0.1s">
                            <div className='mp-box pt-5'>
                                <div className='text-center mb-3 mp-icon'>
                                    <img src={getImages('mp-icon1.svg')} alt='icon' />
                                </div>
                                <div className='mp-title text-center text-green'>Service</div>
                            </div>
                        </div>
                        <div className='mp-col wow fadeInUp' data-wow-delay="0.2s">
                            <div className='mp-box pt-5'>
                                <div className='text-center mb-3 mp-icon'>
                                    <img src={getImages('mp-icon2.svg')} alt='icon' />
                                </div>
                                <div className='mp-title text-center text-green'>Networking</div>
                            </div>
                        </div>
                        <div className='mp-col wow fadeInUp' data-wow-delay="0.3s">
                            <div className='mp-box pt-5'>
                                <div className='text-center mb-3 mp-icon'>
                                    <img src={getImages('mp-icon3.svg')} alt='icon' />
                                </div>
                                <div className='mp-title text-center text-green'>Delivery</div>
                            </div>
                        </div>
                        <div className='mp-col wow fadeInUp' data-wow-delay="0.4s">
                            <div className='mp-box pt-5'>
                                <div className='text-center mb-3 mp-icon'>
                                    <img src={getImages('mp-icon4.svg')} alt='icon' />
                                </div>
                                <div className='mp-title text-center text-green'>Storage</div>
                            </div>
                        </div>
                        <div className='mp-col wow fadeInUp' data-wow-delay="0.5s">
                            <div className='mp-box pt-5'>
                                <div className='text-center mb-3 mp-icon'>
                                    <img src={getImages('mp-icon5.svg')} alt='icon' />
                                </div>
                                <div className='mp-title text-center text-green'>Vehicles</div>
                            </div>
                        </div>
                        <div className='mp-col wow fadeInUp' data-wow-delay="0.6s">
                            <div className='mp-box pt-5'>
                                <div className='text-center mb-3 mp-icon'>
                                    <img src={getImages('mp-icon6.svg')} alt='icon' />
                                </div>
                                <div className='mp-title text-center text-green'>Events</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='drop-line-wrap'
                style={{
                    backgroundImage: `url(${getImages('become-member.webp')}) no-repeat center center`,
                    backgroundSize: 'cover'
                }}
            >
                <div className='container mxw-980'>
                    <div className='main-heading mb-3 text-uppercase text-center'>Drop Us a Line</div>
                    <p className='text-center font-1-3em text-white'>Call or Text us at 800-345-6789</p>
                    <CommonForm/>
                </div>
            </section>
        </>
    )
}

export default OurCommunity