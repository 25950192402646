import React from 'react'

const ForgotPassword = ({ close, handleRegisterModal }) => {
    return (
        <>
            <div className="modal-content register-content forgot-modal">
                <div className="modal-body pd-25-65">
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <div className="register-title mb-0">FORGOT PASSWORD</div>
                    <div className='register-about text-center mt-4'>Please winter your email address below <br />and we will re-send your login credentials</div>
                    <div className="register-wrapper">
                        <form className="login-form">
                            <div className="register-form-group form-group mt-4">
                                <label>EMAIL</label>
                                <input type="text" className="form-control" name="signin_email" autoComplete="new-password" />
                            </div>
                            <div className="login-btn text-center mt-4">
                                <button type="submit" className="green-btn w-50 lg-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword