import React, { useState, useEffect, useRef, useContext } from 'react'
import { getImages } from '../../Componment/const'
import { Field, Form, Formik } from 'formik';
import { Link, NavLink } from 'react-router-dom'
import ValidationError from '../../Componment/Errors/ValidationError';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CountUp from 'react-countup';
import { VehicleContext } from '../../Componment/Context/VehicleContext';

const Service = () => {
  const {submitContactForm} = useContext(VehicleContext);
  
  const [trigger, setTrigger] = useState(false);

  const handleScrollEvent = async () => {
    const element = await document.getElementById("data");
    const elementPosition = await element.getBoundingClientRect().top;

    if (window.pageYOffset > elementPosition) {
      setTrigger(true);
    }
  };

  const increment = () => {
    // Perform the action you want to trigger when the element comes into view
    console.log("Element is in view!");
    // For example, you might increment a counter or perform some other action here
  };

  const registerEvent = () => {
    document.addEventListener("scroll", handleScrollEvent);
  };

  const unRegisterEvent = () => {
    document.removeEventListener("scroll", handleScrollEvent);
  };

  useEffect(() => {
    if (trigger) {
      increment();
    }

    // Register the event inside useEffect after the component mounts
    registerEvent();

    // Cleanup the registered event once the component is unmounted from 
    // the DOM
    return () => {
      unRegisterEvent();
    };
  }, [trigger]);

  const scrollToSection = () => {
    const section = document.getElementById('ScheduleAppointment');
    section.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <section className='service-main' style={{
        backgroundImage: `#161616 url(${getImages('hero-bg.webp')}) no-repeat center top`,
        backgroundSize: '100%'
      }}>
        <section className='service-banner-wrap position-relative'>
          <div className='service-banner-img wow fadeInRight' data-wow-delay="0.2s">
            <div className='container'>
              <img src={getImages('ferrari-img.webp')} alt='hero' />
            </div>
          </div>
          <div className='service-banner'>
            <div className='container mxw-1366'>
              <div className='main-heading text-uppercase font-2-8em wow fadeInDown' data-wow-delay="0.2s">KENT MOTOR CLUB SERVICE DEPARTMENT</div>
              <div className='service-md-title mt-3 wow fadeInDown' data-wow-delay="0.4s">The Nation’s Best Ferrari Techs</div>
              <div className='mt-4 wow fadeInDown' data-wow-delay="0.6s">
                <div>
                  <button className="green-btn lg-btn text-center px-3 w-350" onClick={scrollToSection}>Schedule Appointment</button>
                </div>
                <div className='mt-3'>
                  <Link to="/ferrari-service" className="transparent-green-btn lg-btn text-center px-3 d-inline-block w-350">View Pricing</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='service-featured-wrap'>
          <div className='container'>
            <div className='row gap-10'>
              <div className='col-md-6 wow fadeInLeft' data-wow-delay="0.1s">
                <div className='sf-img'>
                  <img src={getImages('bitmap.webp')} alt='bit' />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='sf-box h-100'>
                  <ul>
                    <li className='d-flex wow fadeInRight' data-wow-delay="0.3s">
                      <div className='sfb-icon pt-1'>
                        <img src={getImages('icon-check.svg')} alt='check' />
                      </div>
                      <div className='sfb-content ps-3'>
                        <div className='sfb-title mb-1'>Ferrari Technicians </div>
                        <p>Ferrari factory trained technicians and staff</p>
                      </div>
                    </li>
                    <li className='d-flex wow fadeInRight' data-wow-delay="0.5s">
                      <div className='sfb-icon pt-1'>
                        <img src={getImages('icon-check.svg')} alt='check' />
                      </div>
                      <div className='sfb-content ps-3'>
                        <div className='sfb-title mb-1'>Factory OE Parts</div>
                        <p>We only use factory original parts</p>
                      </div>
                    </li>
                    <li className='d-flex wow fadeInRight' data-wow-delay="0.7s">
                      <div className='sfb-icon pt-1'>
                        <img src={getImages('icon-check.svg')} alt='check' />
                      </div>
                      <div className='sfb-content ps-3'>
                        <div className='sfb-title mb-1'>Concierge Delivery</div>
                        <p>We offer flat-bed pickup and delivery</p>
                      </div>
                    </li>
                    <li className='d-flex wow fadeInRight' data-wow-delay="0.9s">
                      <div className='sfb-icon pt-1'>
                        <img src={getImages('icon-check.svg')} alt='check' />
                      </div>
                      <div className='sfb-content ps-3'>
                        <div className='sfb-title mb-1'>Unmatched Pricing</div>
                        <p>Low expense structure passes along value that cannot be matched </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='profit-margins-wrap'>
          <div className='container mxw-1230'>
            <p className='wow fadeInUp' data-wow-delay="0.2s">Superior Service, Superior Pricing… Kent Motor Club is where the wise investor services their prized possession. At Kent Motor Club Service Department we favor excellence over profit margins. State-of-the-art diagnostic tools, genuine manufacturer parts and more experience than we care to promote- our service center is the backbone of the dealership. Every service is executed with precise standards and meticulous detail. From routine maintenance to major’s, our team cares for your car like it’s their own.</p>
            <div className='pm-title mt-5 pt-4 d-flex align-items-center justify-content-between wow fadeInUp' data-wow-delay="0.4s">
              <img src={getImages('quote-left.svg')} />
              <div>We favor <span className='text-green'>excellence</span> over profit margins</div>
              <img src={getImages('quote-right.svg')} />
            </div>
          </div>
        </section>
        <section className='profit-margins-wrap profile-logos-wrap'>
          <div className='container mxw-1230'>
            <div className='d-flex align-items-center justify-content-between pm-logos'>
              <div className='wow fadeInUp' data-wow-delay="0.4s">
                <img src={getImages('logo1.svg')} />
              </div>
              <div className='wow fadeInUp' data-wow-delay="0.5s">
                <img src={getImages('logo2.webp')} />
              </div>
              <div className='wow fadeInUp' data-wow-delay="0.6s">
                <img src={getImages('logo3.webp')} />
              </div>
              <div className='wow fadeInUp' data-wow-delay="0.7s">
                <img src={getImages('logo4.webp')} />
              </div>
              <div className='wow fadeInUp' data-wow-delay="0.8s">
                <img src={getImages('logo5.webp')} />
              </div>
              <div className='wow fadeInUp' data-wow-delay="0.9s">
                <img src={getImages('logo6.webp')} />
              </div>
            </div>
          </div>
        </section>
        <section className='reliable-wrap' id="data">
          <div className='container'>
            <div className='reliable-title wow fadeInUp' data-wow-delay="0.2s">RELIABLE SERVICE FROM THE NATION’S TOP TECHNICIANS</div>
            <div className='row mt-5 reliable-block'>
              <div className='col-md-3 py-1'>
                <div className='reliable-value mb-3'>
                  {trigger ? (
                    <>
                      <CountUp start={0} end={30} duration={3}> +</CountUp>
                    </>
                  ) : (
                    <div>0</div>
                  )}
                </div>
                <div className='reliable-label'>Years of Experience</div>
              </div>
              <div className='col-md-3 py-1'>
                <div className='reliable-value mb-3'>
                  {trigger ? (
                    <>
                      <CountUp start={0} end={4200} duration={3}></CountUp>
                    </>
                  ) : (
                    <div>0</div>
                  )}
                </div>
                <div className='reliable-label'>Vehicles Repaired</div>
              </div>
              <div className='col-md-3 py-1'>
                <div className='reliable-value mb-3'>
                  {trigger ? (
                    <>
                      <CountUp start={0} end={3500} duration={3}></CountUp>
                    </>
                  ) : (
                    <div>0</div>
                  )}
                </div>
                <div className='reliable-label'>Satisfied Customers</div>
              </div>
              <div className='col-md-3 py-1'>
                <div className='reliable-value mb-3'>
                  {trigger ? (
                    <>
                      <CountUp start={0} end={140} duration={3}></CountUp>
                    </>
                  ) : (
                    <div>0</div>
                  )}
                </div>
                <div className='reliable-label'>Cities Served</div>
              </div>
            </div>
          </div>
        </section>
        <section className='become-member-wrap service-member-wrap'
          style={{
            background: `url(${getImages('service-bg-img-cta.webp')}) no-repeat center center`,
            backgroundAttachment: 'fixed'
          }}
        >
          <div className='become-member-pos'>
            <div className='main-heading text-center text-uppercase wow fadeInDown' data-wow-delay="0.2s">Schedule Your Appointment today!</div>
            <div className='main-heading text-center fw-700 mt-3 font-1-9em wow fadeInUp' data-wow-delay="0.4s">516-345-6789</div>
            <div className="text-center mt-30 wow fadeInUp" data-wow-delay="0.6s">
              <button className="green-btn lg-btn px-5" onClick={scrollToSection}>Schedule Appointment</button>
            </div>
          </div>
        </section>
        <section className='map-wrap'>
          <div className='container'>
            <div className='map-box wow fadeIn' data-wow-delay="0.2s">
              <iframe src="https://snazzymaps.com/embed/606096" width="100%" height="600px" style={{ border: 'none' }}></iframe>
            </div>
          </div>
        </section>
        <section className='contact-wrap' id="ScheduleAppointment">
          <div className='container'>
            <div className='gray-box'>
              <div className='main-heading text-center text-uppercase mb-3 wow fadeInUp' data-wow-delay="0.2s">Schedule your Service</div>
              <div className='md-title fw-400 pb-5 text-center wow fadeInDown' data-wow-delay="0.4s">Call or Text us at 516-345-6789</div>
              <Formik
                // validationSchema={validationSchema}
                initialValues={{
                  vehicle: '',
                  full_name: '',
                  email: '',
                  phone: '',
                  preferable_date: '',
                  preferable_time: '',
                  requested_services: '',
                }}
                onSubmit={(values) => {
                  console.log(values);
                  submitContactForm(values);
                  alert("Thank you for your submission");
                }}
              >
                {({ values, setFieldValue, field, form }) => (
                  <Form className="service-from" autoComplete="off">
                    <div className='row mx-50'>
                      <div className='col-md-6 px-50 wow fadeInLeft' data-wow-delay="0.4s">
                        <div className='row'>
                          <div className='form-group col-12'>
                            <div className='cs-label'>Vehicle</div>
                            <Field
                              type="text"
                              name="vehicle"
                              className="form-control"
                              placeholder="Enter Year Make Model Trim"
                            />
                            <ValidationError name="vehicle" />
                          </div>
                          <div className='form-group col-12'>
                            <div className='cs-label'>Full Name</div>
                            <Field
                              type="text"
                              name="full_name"
                              className="form-control"
                            />
                            <ValidationError name="full_name" />
                          </div>
                          <div className='form-group col-12'>
                            <div className='cs-label mb-2'>Email</div>
                            <Field
                              type="text"
                              name="email"
                              className="form-control"
                            />
                            <ValidationError name="email" />
                          </div>
                          <div className='form-group col-12'>
                            <div className='cs-label mb-2'>Phone</div>
                            <Field
                              type="text"
                              name="phone"
                              className="form-control"
                            />
                            <ValidationError name="phone" />
                          </div>
                          <div className='form-group col-6'>
                            <div className='cs-label'>Preferable Date</div>
                            <Field name="preferable_date" className="w-100">
                              {({ form, field }) => (
                                <DatePicker
                                  className="form-control w-100 calendar-field"
                                  id="date"
                                  {...field}
                                  selected={field.value}
                                  onChange={(date) => form.setFieldValue(field.name, date)}
                                  dateFormat="MM/dd/yyyy"
                                />
                              )}
                            </Field>
                            <ValidationError name="preferable_date" />
                          </div>
                          <div className='form-group col-6'>
                            <div className='cs-label'>Preferable Time</div>
                            <Field
                              type="text"
                              name="preferable_time"
                              className="form-control time-field"
                            />
                            {/* <Field name="preferable_time">
                              {({ form, field }) => (
                                <TimePicker
                                  className="form-control"
                                  id="time"
                                  {...field}
                                  onChange={(time) => form.setFieldValue(field.name, time)} 
                                  value={field.value}
                                  format="HH:mm"
                                  disableClock={true}
                                />
                              )}
                            </Field> */}
                            <ValidationError name="preferable_time" />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 px-50 wow fadeInRight' data-wow-delay="0.6s">
                        <div className='form-group'>
                          <div className='cs-label'>Requested Services</div>
                          <Field
                            as="textarea"
                            name="requested_services"
                            className="form-control h-370"
                          />
                          <ValidationError name="requested_services" />
                        </div>
                        <div className='mt-4 text-end'>
                          <button type='submit' className='green-btn lg-btn w-300'>Send</button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Service