import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './Componment/Frontend/Layout';
import VehicleContextProvider from './Componment/Context/VehicleContext';
import AuctionContextProvider from './Componment/Context/AuctionContext';
import UserContextProvider from './Componment/Context/UserContext';
import ChatContextProvider from './Componment/Context/ChatContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <UserContextProvider>
      <VehicleContextProvider>
        <AuctionContextProvider>
          <ChatContextProvider>
            <App />
          </ChatContextProvider>
        </AuctionContextProvider>
      </VehicleContextProvider>
    </UserContextProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
