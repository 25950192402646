import React, { useState, useEffect, useContext } from 'react';
import { getImages } from '../../Componment/const'
import OwlCarousel from "react-owl-carousel";
import { VehicleContext } from '../../Componment/Context/VehicleContext';
import ModalLayout from '../../Componment/Frontend/Common/ModalLayout';
// import SelectMakeModal from '../../Componment/Frontend/Inventory/SelectMakeModal';
import ReadMore from '../../Componment/Frontend/Common/ReadMore';
import SelectFilterModal from '../../Componment/Frontend/Inventory/SelectFilterModal';

const srpList = [
    {
        images: [getImages('srp-img1.webp')],
        vehicle_name: '2022 Rolls-Royce Cullinan',
        mileage: '1,100',
        price: '$529k'
    },
    {
        images: [getImages('srp-img2.webp')],
        vehicle_name: '2023 Porsche 911 Carrera T',
        mileage: '2,700',
        price: '$280k'
    },
    {
        images: [getImages('srp-img3.webp')],
        vehicle_name: '2022 Porsche Macan Turbo',
        mileage: '1,800',
        price: '$145k'
    },
    {
        images: [getImages('srp-img4.webp')],
        vehicle_name: '2016 Porsche 911 R Coupe',
        mileage: '1,400',
        price: '$449k'
    },
    {
        images: [getImages('srp-img5.webp')],
        vehicle_name: '2023 Porsche 911 GT3 RS',
        mileage: '700',
        price: '$429k',
        LatestDrop: true
    },
    {
        images: [getImages('srp-img1.webp')],
        vehicle_name: '2022 Rolls-Royce Cullinan',
        mileage: '1,100',
        price: '$529k'
    },
    {
        images: [getImages('srp-img2.webp')],
        vehicle_name: '2023 Porsche 911 Carrera T',
        mileage: '2,700',
        price: '$280k'
    },
    {
        images: [getImages('srp-img3.webp')],
        vehicle_name: '2022 Porsche Macan Turbo',
        mileage: '1,800',
        price: '$145k'
    },
    {
        images: [getImages('srp-img4.webp')],
        vehicle_name: '2016 Porsche 911 R Coupe',
        mileage: '1,400',
        price: '$449k'
    },
]

const Srp = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [pageSize, setPageSize] = useState(9);
    const [isDataLoaded, setIsDataLoaded] = useState(true);
    const [isOpacity, setIsOpacity] = useState(true);

    const { getVehicleData, filteredVehicleData, setSearchText, numberFormatter, vehicleYears, vehicleMakes, vehicleModels, vehicleTrims, setVehicleYears,setVehicleMakes,setVehicleModels,setVehicleTrims } = useContext(VehicleContext);
    const [timer, setTimer] = useState(null);

    const [vehicleSearchText, setVehicleSearchText] = useState("");
    useEffect(() => {
        if (filteredVehicleData) {
            setIsDataLoaded(false);
            setTimeout(() => {
                setIsOpacity(false);
            }, 100);
        }
    }, [filteredVehicleData]);

    const queryParameters = new URLSearchParams(window.location.search);
    const dealer_inventory = queryParameters.has("dealer_inventory");

    const searchInputChanged = (val) => {

        setVehicleSearchText(val);

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            setSearchText(val);
        }, 500)

        setTimer(newTimer)
    }

    useEffect(() => {
        console.log("srp useEffect");

        getVehicleData();

        const handleScroll = () => {
            const topSection = document.getElementById('srpFilter');
            if (topSection) {
                const topSectionRect = topSection.getBoundingClientRect();
                const isTopSectionVisible = topSectionRect.top <= 0;
                setIsSticky(isTopSectionVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollData = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = window.innerHeight;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 100;

        if (scrolledToBottom) {
            fetchMoreData();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollData);
        return () => {
            window.removeEventListener('scroll', handleScrollData);
        };
    }, []);

    const fetchMoreData = () => {
        setPageSize(prevPageSize => prevPageSize + 9);
    };
    const [srpFilter, setSrpFilter] = useState(false);
    const handelSrpFilter = () => {
        setSrpFilter(true)
    }
    const closeSrpFilter = () => {
        setSrpFilter(false)
    }
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        if (isHovered) {
          setIsHovered(true);
        }
      };
    
    // Event handler for mouse leave
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // Event handler for button click
    const goBackFlip = () => {
        setIsHovered(false); // Remove the hover effect when button is clicked
      };
      const [openMakeModal, setOpenMakeModal] =  useState(false);
      const handleMakeModal = () => {
        setOpenMakeModal(true)
      }
    //   const closeMakeModal = () => {
    //     setOpenMakeModal(false)
    //   }

    const [openFilterModal, setOpenFilterModal] =  useState(false);

    const [filterData,setFilterData] = useState([]);
    const [filterMethod,setFilterMethod] = useState([]);

    const [filterField,setFilterField] = useState("");

    const handleFilterModal = (field) => {
        setOpenFilterModal(true);

        switch(field) {
            case "year": setFilterData(vehicleYears); break;
            case "make": setFilterData(vehicleMakes); break;
            case "model": setFilterData(vehicleModels); break;
            case "trim": setFilterData(vehicleTrims); break;
        }

        setFilterField(field);
    }

    const closeMakeModal = () => {
        setOpenFilterModal(false)
    }

    const updateFilter = (filter_data) => {

        switch(filterField) {
            case "year": setVehicleYears(filter_data); break;
            case "make": setVehicleMakes(filter_data); break;
            case "model": setVehicleModels(filter_data); break;
            case "trim": setVehicleTrims(filter_data); break;
        }
    }

    return (
        <>
            <section className='srp-wrap'>
                <div className='container'>
                    <div className='d-flex mb-4 align-items-center justify-content-between'>
                        <div className='main-heading fw-700 wow fadeInDown' data-wow-delay="0.2s">Hand Selected Inventory</div>
                        <div className='d-lg-none'>
                            <button type='button' className='filter-btn' onClick={handelSrpFilter}>Filter <img src={getImages('filter_icon.svg')} alt='icon'/></button>
                        </div>
                    </div>
                </div>
                <div id="srpFilter" className={`product-block ${isSticky ? 'sticky' : ''}`}>
                    <div className='srp-filter-block'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-4'>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex search-box'>
                                            <input type='text' className='form-control'
                                                placeholder='Search'
                                                value={vehicleSearchText}
                                                onChange={(e) => { searchInputChanged(e.target.value) }}
                                            />
                                            <button className='search-icon'></button>
                                        </div>
                                        <div className='d-lg-none sticky-filter-btn'>
                                            <button type='button' className='filter-btn' onClick={handelSrpFilter}>Filter <img src={getImages('filter_icon.svg')} alt='icon'/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-md-8 text-end srp-filter-blk ${srpFilter ? "active": ""}`}>
                                    <div className='srp-filter-header d-lg-none'>
                                        <div>Filters</div>
                                            <button className='sf-close-icon' onClick={closeSrpFilter} type='button'><img src={getImages('close-anticon.svg')}/></button>
                                    </div>
                                    <div className='srp-filter-body d-inline-flex gap-3'>
                                        <div className='d-lg-none mb-4'>
                                            <div className='sm-title text-start'>Applied Filters</div>
                                            <div className='mt-3 text-start'>
                                                <span className='sf-tag me-2'>2023 <img src={getImages('cross_icon.svg')}/></span>
                                                <span className='sf-tag me-2'>Porsche <img src={getImages('cross_icon.svg')}/></span>
                                            </div>
                                        </div>
                                        <button className='srp-filter-btn' type='button' onClick={() => handleFilterModal('year')}>
                                            <img src={getImages('event.svg')} alt='icon' />
                                            <span>Year</span>
                                            <span className='srp-fl-arrow'></span>
                                        </button>
                                        <button className='srp-filter-btn' type='button' onClick={() => handleFilterModal('make')}>
                                            <img src={getImages('location_city.svg')} alt='icon' />
                                            <span>Make</span>
                                            <span className='srp-fl-arrow'></span>
                                        </button>
                                        <button className='srp-filter-btn' type='button' onClick={() => handleFilterModal('model')}>
                                            <img src={getImages('directions_car.svg')} alt='icon' />
                                            <span>Model</span>
                                            <span className='srp-fl-arrow'></span>
                                        </button>
                                        <button className='srp-filter-btn' type='button' onClick={() => handleFilterModal('trim')}>
                                            <img src={getImages('loupe.svg')} alt='icon' />
                                            <span>Trim</span>
                                            <span className='srp-fl-arrow'></span>
                                        </button>
                                        {/*<button className='srp-filter-btn'>
                                            <img src={getImages('Options-vertical.svg')} alt='icon' />
                                            <span>More</span>
                                            <span className='srp-fl-arrow'></span>
                                        </button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='product-list mt-3'>
                            <div className='row'>
                                {isDataLoaded ?
                                    <div className='py-5 my-5 text-center'>
                                        <img src={getImages('gears_green.svg')} alt='gears' />
                                    </div> :
                                    filteredVehicleData && (dealer_inventory ? filteredVehicleData : srpList).slice(0, pageSize).map((vehicle, index) =>
                                        
                                            vehicle.LatestDrop ? (
                                                <div className='col-md-8 animated zoomIn'
                                                    style={{
                                                        visibility: 'visible',
                                                        animationDelay: '0.2s',
                                                        animationName: 'zoomIn'
                                                    }}
                                                    key={index}
                                                >
                                                    <div className='pro-box pro-full-width d-flex align-items-center'>
                                                        <div className='pro-top'>
                                                            <img src={vehicle.images[0]} alt='srp' />
                                                        </div>
                                                        <div className='pro-bottom p-0 ps-4'>
                                                            <div className='lt-main-title text-uppercase mb-4'>Latest Drop</div>
                                                            <div className="sm-title mb-2">{vehicle.vehicle_name}</div>
                                                            <div className='d-flex align-items-center justify-content-between mt-5 pt-2'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="xs-title pe-3">Price:</div>
                                                                    <div className="sm-title">{numberFormatter(vehicle.price, true)}</div>
                                                                </div>
                                                                <div>
                                                                    <div className="xs-title">Mileage: {numberFormatter(vehicle.mileage)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    // goBackFlipAnimation
                                                    <div className='col-md-4 animated zoomIn'
                                                        style={{
                                                            visibility: 'visible',
                                                            animationDelay: '0.2s',
                                                            animationName: 'zoomIn'
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className='pro-box'>
                                                            <div className={`pro-content`}>
                                                                <div className='before-hover'>
                                                                    <div className='pro-top'>
                                                                        <img src={vehicle.images[0]} alt='srp' />
                                                                    </div>
                                                                    <div className='pro-bottom'>
                                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                                            <div className="sm-title font-1-1em">{vehicle.vehicle_name}</div>
                                                                            <div>
                                                                                <button className='info-icon'>
                                                                                    <img src={getImages('info-icon.svg')} alt='icon' />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="xs-title font-1em mb-2">{numberFormatter(vehicle.mileage)}</div>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="sm-title font-1-1em">{numberFormatter(vehicle.price, true)}</div>
                                                                            <div><a className="green-btn" href={"/vdp?vin=" + vehicle.vin}>Vehicle Details</a></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='after-hover'>
                                                                    <div className='pro-top'>
                                                                        <OwlCarousel
                                                                            className="owl-theme srplist-slider"
                                                                            loop={true}
                                                                            margin={0}
                                                                            items={1}
                                                                            nav={true}
                                                                            dots={true}
                                                                            autoplay={true}
                                                                            autoplayTimeout={2000}
                                                                        >
                                                                            {vehicle.images.slice(0, 3).map((v, i) => {
                                                                                return (
                                                                                    <div className='item' key={i}>
                                                                                        <img src={v} alt='srp' />
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </OwlCarousel>
                                                                    </div>
                                                                    <div className='pro-bottom'>
                                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                                            <div className='d-flex align-items-center w-60'>
                                                                                <div className='xs-title fw-400 w-30'>Stock #</div>
                                                                                <div className='sm-title w-70'>{vehicle.stockno}</div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center w-40'>
                                                                                <div className='xs-title fw-400 w-60'>Interior Color</div>
                                                                                <div className='sm-title w-40'>{vehicle.interior_color}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                                            <div className='d-flex align-items-center w-60'>
                                                                                <div className='xs-title fw-400 w-30'>VIN</div>
                                                                                <div className='sm-title w-70'>{vehicle.vin}</div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center w-40'>
                                                                                <div className='xs-title fw-400 w-60'>Exterior Color</div>
                                                                                <div className='sm-title w-40'>{vehicle.exterior_color}</div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className='custom-hr' />
                                                                        <ReadMore
                                                                            key={index}
                                                                            text={vehicle.description}
                                                                            maxChars={230}
                                                                            sectionId="aboutVdp"
                                                                            readMoreUrl={"/vdp?vin=" + vehicle.vin}
                                                                        />
                                                                        {/* <p>We are proud to present this beautiful Porsche Taycan. Finished in Volcano Grey lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt &nbsp;<span className='text-green'>Read More</span></p> */}
                                                                        <div className="d-flex align-items-center justify-content-between mt-4 pt-2 srp-actions-flex">
                                                                            <div>
                                                                                <button type='button' className='view-sticker-btn text-green'><span className='doc_icon'></span> View Window Sticker</button>
                                                                            </div>
                                                                            <div><a className="green-btn" href={"/vdp?vin=" + vehicle.vin} >Vehicle Details</a></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-center d-none'>
                                                                        <button className='flip-back-btn' type='button'
                                                                            onClick={goBackFlip}
                                                                        >
                                                                            <img src={getImages('refresh-icon.svg')}/>
                                                                            Flip Card
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalLayout open={openFilterModal} close={closeMakeModal} darkThemeCls='true' modalWidth={970}>
                <SelectFilterModal close={closeMakeModal} filterData={filterData} updateFilter={updateFilter} filterField={filterField}/>
            </ModalLayout>
        </>
    )
}

export default Srp