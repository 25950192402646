import React, { useState, useEffect, useContext } from 'react'
import ChatMessageField from './ChatMessageField'
import ChatStandaloneList from './ChatStandaloneList'
import ChatBoxHeader from './ChatBoxHeader'
import { useLocation } from 'react-router';
import ChatMessageBody from './ChatMessageBody'
import ChatLayout from './ChatLayout'
import { ChatContext } from '../../Context/ChatContext';
import { UserContext } from '../../Context/UserContext';
import { AuctionContext } from '../../Context/AuctionContext';

const ChatStandalone = ({closeChatModal}) => {

    const { chatUsers, chatDataByUser, socketRef, sendChat, unreadByUser, markChatRead, allUsers, uploadDocument, chatAttachmentInfo } = useContext(ChatContext);
    const { user } = useContext(UserContext);

    const [activeChatUser, setActiveChatUser] = useState(null);

    const queryParameters = new URLSearchParams(window.location.search)
    const new_chat = queryParameters.get("new_chat");

    const [newChat, setNewChat] = useState(new_chat !== null ? true : false);

    //const [chatMsg, setChatMsg] = useState("");
    const [chatOpen, setChatOpen] = useState(false);
    const [updateChat, setUpdateChat] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const openChat = (with_user) => {
        console.log("opening chat",with_user, chatDataByUser, unreadByUser, chatUsers);

        socketRef.current.emit('message', {
            chatCommand : "getSingleChatHistory",
            with_user : with_user
        });

        if(unreadByUser[with_user] > 0) markChatRead(with_user);

        setActiveChatUser(with_user);
        setChatOpen(true);
    }

    const backArrowBtn = () => {
        setChatOpen(false)
    }
    const { state } = useLocation();

    //const [messages, setMessages] = useState([]);

    const now = new Date();
    const formatDateTime = (date) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // 24-hour format
        };
        return date.toLocaleString(undefined, options);
    }
    const formattedDateTime = formatDateTime(now);
    const sendMessage = (message) => {

        console.log(message,activeChatUser);

        if (message && activeChatUser) {
            //const newMessage = { text: message, id: Date.now(), className: "repaly", time: formattedDateTime, data: "", messageType: "text" };

            sendChat(message,activeChatUser);
            //chatMsg.messages = [...chatMsg.messages, newMessage];
            //setChatMsg(chatMsg);
            //setMessage('');
        }
    };

    const uploadFile = (file) => {
        console.log(file);

        setIsLoading(true)
        if (activeChatUser && file[0]) {

            console.log(file[0]);

            var fileSize = 0;
            var messageType = "";
            // if(['application/pdf'].includes(file[0].type)>-1){
            if((file[0].type).includes('application') || (file[0].type).includes('text')){
                fileSize = ((file[0].size / (1024*1024)));
                var ext = (fileSize<=0)?"KB":"MB";
                fileSize = (fileSize.toFixed(2)).padStart(2, ' ')+ext;
                messageType = "pdf"
            }

            if(['image/jpeg','image/jpg', 'image/png'].indexOf(file[0].type) > -1){
                messageType = "png"
            }

            const formData = new FormData();
            formData.append('file', file[0]);
            formData.append('folder', 'chatfiles/' + user.username + '/');
            formData.append('fileName',file[0].name);
            //formData.append('base_url',true);

            const upload = uploadDocument(formData);
            upload.then((data) => {
                if (data.url && data.data && data.data.key) {
                    sendChat("",activeChatUser,data.data.key,data.url);
                    
                } else if(data.error) alert(data.error);
            });

            setIsLoading(false);
        }
            
    }
    
    useEffect(()=>{
        if(state){
            openChat(state.name);
        }
    },[state]);

    useEffect(()=>{
        setNewChat(new_chat !== null ? true : false);
    },[new_chat]);

    useEffect(() => {
        openChat('kentmotorclub')
    },[])

    return (
        <>
            <ChatLayout>

                <div className={`chatbox ${chatOpen ? "active" : ""}`}>
                    <div className="chat-dialog-scrollable">
                        <div className="modal-content p-0">
                            <ChatBoxHeader
                                chatHeader='Kent Motor Club'
                                backArrowBtn={backArrowBtn}
                            />
                            <ChatMessageBody
                                chatList={allUsers}
                                chatData={chatDataByUser[activeChatUser]}
                                message={"Chat Standalone"}
                                chatAttachmentInfo={chatAttachmentInfo}
                            />
                            {<ChatMessageField
                                sendMessage={sendMessage}
                                chatUpdated={updateChat}
                                uploadFile={uploadFile}
                                isLoading={isLoading}
                            />}
                        </div>
                    </div>
                </div>
            </ChatLayout>
        </>
    )
}

export default ChatStandalone