import React from 'react'

const AddToWishlist = ({ close }) => {
    return (
        <>
            <div className="modal-content add-whishlist-modal">
                <div className="modal-header">
                    <h1 className="modal-title text-uppercase">
                        ADD TO WATCHLIST
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body px-4 py-4">
                <div className='register-about text-center mb-3 fw-900'><strong>We'll add this Vehicle to your Watchlist</strong></div>
                <div className='register-about text-center mb-3 text-center'>How would you like to be notified when this vehicle goes live?</div>
                    <form className="service-from form-horizontal">
                        <div className="form-group mb-3">
                            <label className="custom-checkbox mb-2">
                                <input type="checkbox" id="via_email"/>
                                <label htmlFor="via_email">Via Email</label>
                            </label>
                            <div className="">
                                <input type="text" className="form-control" name="Via_Email" autoComplete="new-password" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="custom-checkbox mb-2">
                                <input type="checkbox" id="Via_Text_Message"/>
                                <label htmlFor="Via_Text_Message">Via Text Message</label>
                            </label>
                            <div className="">
                                <input type="text" className="form-control" name="Via_Text_Message" autoComplete="new-password" />
                            </div>
                        </div>
                        <div className="register-btn mt-4 text-center">
                            <button type="submit" className="green-btn w-50 lg-btn">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
  )
}

export default AddToWishlist