import React from 'react'

const ChatSearchBox = ({openSearch, closeSearchBox}) => {
    return (
        <>
            <div className={`chat-search-box ${openSearch? "open" : ""}`}>
                <div className='chat-search-header'><button type='button' className='chat-exit-icon' onClick={closeSearchBox}></button> Search Messages</div>
                <div className='chat-search-body'>
                    <div className="msg-search w-100">
                        <div className='position-relative search-input w-100'>
                            <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Search" aria-label="search" />
                            <span className="gray-search-icon"></span>
                        </div>
                    </div>
                    <div className='search-keyword-text'>
                        Search keywords within <br />this chat
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatSearchBox