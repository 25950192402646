import { ErrorMessage, useField } from "formik";
const ValidationError = (props) => {
    const [field, meta] = useField(props);
    return <>
        <ErrorMessage name={field.name} render={
            msg =>
                <>
                    <label htmlFor={field.name} className="error">
                        {msg}
                    </label>
                </>
            }
        />

    </>

}
export default ValidationError
