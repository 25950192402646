import React from 'react'
import { getImages } from '../../const'

const AskAQuestion = () => {
  return (
    <>
        <div className='md-title mt-4 mb-3 wow fadeInUp' data-wow-delay="0.6s">ASK A QUESTION</div>
        <textarea className="form-control ask-question-control wow fadeInUp" data-wow-delay="0.4s" placeholder='Add your comment here' rows={5}></textarea>
        <div className='text-end mt-4 wow fadeInUp' data-wow-delay="0.6s">
            <button type='button' className='transparent-btn mnw-220 me-3 attact-photo-btn position-relative'>
                <img style={{ maxWidth: '25px', verticalAlign: '-6px' }} src={getImages('attach.webp')} />
                ATTACH PHOTO
                <input type='file' />
            </button>
            <button type='button' className='green-btn mnw-220 py-3'>Submit</button>
        </div>
    </>
  )
}

export default AskAQuestion