import {createContext} from 'react'
import { UserContext } from './UserContext';
import { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import axios from 'axios';

export const ChatContext = createContext();

export const ChatContextProvider = ({children}) => {

    /* chat stuff */

    const { user, logout } = useContext(UserContext);

    const [message, setMessage] = useState(false);

    const [chatList, setChatList] = useState([]);

    const [chatAttachmentInfo,setChatAttachmentInfo] = useState([]);

    const [unreadMessages,setUnreadMessages] = useState(0);

    const socketRef = useRef();

    const [chatSmBox, setChatSmBox] = useState(false);
    const [chatDataByUser, setChatDataByUser] = useState([]);

    const [chatUsers,setChatUsers] = useState([]);

    const [unreadByUser, setUnreadByUser] = useState([]);

    const closeSmChat = (removeChat) => {
        console.log("removing",removeChat);
        setChatUsers(chatUsers.filter(name => name !== removeChat));
    }

    const submitForm = (name)=>{
        
        const now = new Date();
        const formatDateTime =  (date) => {
            const options = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true, // 24-hour format
            };
            return date.toLocaleString(undefined, options);
        }
        const formattedDateTime = formatDateTime(now);
      
        const newMessage = { text: message, id: Date.now(), time: formattedDateTime };
        var chat_users  = chatUsers.map((item)=>{ if(item.name == name){ item.messages = [...item.messages, newMessage]} return item;})
        console.log(chat_users);
        setChatUsers(chatUsers);
    }

    const sendChat = (message,to_user,attachment_url="",presigned_url="") => {
        if (message.trim() !== '' || attachment_url.trim() !== '') {
            //console.log(user,message);

            var values = {
                chatCommand: 'privateChat',
                to_user: to_user,
                from_user: user.username,
                msg: message,
                singleChat: true
            };

            console.log("sending chat",values);

            if(attachment_url.trim() !== '') values.attachment_url = attachment_url;
            if(presigned_url.trim() !== '') values.presigned_url = presigned_url;

            socketRef.current.emit('message', values); 

            /*
            shout = {
                'chatMessage': 'privateChat',
                'from_user': obj['from_user'],
                'msg': msg,
                'date': timestamp,
                "is_read":"0"
            };
            

            socketRef.current.emit('message', {
                id: user.username,
                getChatHistory: 'true'
            }); 

            const now = new Date();
            const formatDateTime = (date) => {
                const options = {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true, // 24-hour format
                };
                return date.toLocaleString(undefined, options);
            }
            const formattedDateTime = formatDateTime(now);

            const newMessage = { text: value, id: Date.now(), time: formattedDateTime };
            var chatSmDatas = chatSmData.map((item) => { if (item.name == name) { item.messages = [...item.messages, newMessage];item.value = "" } return item; })
            console.log(chatSmDatas);
            setChatSmData(chatSmDatas);

            */

        }
    }

    const chatTimeSince = (now,date) => {

		var seconds = Math.floor((now - date) / 1000);
        
        if(seconds < 0) seconds = 0;

		var interval = Math.floor(seconds / 31536000);

		if (interval > 1) {
			return interval + " years";
		}
		interval = Math.floor(seconds / 2592000);
		if (interval > 1) {
			return interval + " months";
		}
		interval = Math.floor(seconds / 86400);
		if (interval > 1) {
			return interval + " days";
		}
		interval = Math.floor(seconds / 3600);
		if (interval > 1) {
			return interval + " hours";
		}
		interval = Math.floor(seconds / 60);
		if (interval > 1) {
			return interval + " minutes";
		}
		return Math.floor(seconds) + " seconds";
	}

    const processChatHistory= (chat_history,open_chat=false) => {
        var now = new Date();
        var this_chat;
        var messages_by_user = [];
        var chat_users = [...chatUsers];

        console.log(chat_history);

        chat_history.map((v,i) => {
            v['this_chat_user'] = v['from_user'] == user.username ? v['to_user'] : v['from_user'];
            v['this_chat_company'] = v['from_user'] == user.username ? v['to_user_company'] : v['from_user_company'];
            v['this_chat_fullname'] = v['from_user'] == user.username ? v['to_user_fullname'] : v['from_user_fullname'];
            v['sent'] = v['from_user'] == user.username;
            if(!(v['this_chat_user'] in messages_by_user)) {
                messages_by_user[v['this_chat_user']] = [];
            }

            if(!chat_users.includes(v['this_chat_user']) ) {
                chat_users.push(v['this_chat_user']);
            }
            
            v['date_object'] = new Date(v['created_at']);
            v['message_time'] = v['date_object'].toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            var timeDiff = now.getTime() - v['date_object'].getTime();
            var diffDays = Math.floor(timeDiff / (1000 * 3600 * 24)); 
            v['relative_day'] = diffDays == 0 ? "Today" : (diffDays == -1 ? "Yesterday" : (v['date_object'].getMonth() + 1) + "/" + v['date_object'].getDate());
            v['relative_time'] = chatTimeSince(now,v['date_object']);
            
            messages_by_user[v['this_chat_user']].push(v);
            
            if(!this_chat) {
                this_chat = v['this_chat_user'];
            }

            if(v['attachment_url'] && v['attachment_url'] != "" && !(v['attachment_url'] in chatAttachmentInfo)) {
                getDocumentInfo(v['attachment_url']);
            }
        });

        //console.log("test here",messages_by_user);

        setChatDataByUser((prev) => Object.assign({}, prev, messages_by_user));
        if(open_chat) setChatUsers((prev) => [...chat_users]);
    }

    const baseURL = "https://kentmotorclub.com/"; //window.location.host == "kentmotorclub.com" ? "https://kentmotorclub.com/" : "http://dev.kentmotorclub.com/";

    const Axios = axios.create({
        baseURL: baseURL + "bridge/"
    });

    const uploadDocument = async (values) => {

        const {data} = await Axios.post('sms/s3_upload_file.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            //email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const getDocumentInfo = async (key) => {

        var file_info = [];

        if(key.includes('https://api.twilio.com')) {

            file_info[key] = {'metadata':{'content-type':'image'},'url':key};

        } else {

            var values = {
                'key':key
            };

            console.log(values);

            const {data} = await Axios.post('sms/s3_file_info.php',values).catch(function (error) {
                if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
                else if (error.request) console.log(error.request);
                else console.log('Error', error.message);
                console.log(error.config);
                var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
                //email_axios_error(error_data);
            });
            
            file_info[key] = data;

        }

        setChatAttachmentInfo((prev) => Object.assign({}, prev, file_info));
    }

    const markChatRead = (with_user) => {
        socketRef.current.emit('message', {
            chatCommand : "markChatRead",
            with_user : with_user
        });

        setUnreadMessages(unreadMessages - (unreadByUser[with_user] !== undefined ? unreadByUser[with_user] : 0));

        var messages_by_user = [...unreadByUser];
        messages_by_user[with_user] = 0;
        setUnreadByUser(messages_by_user);

        console.log(unreadByUser,messages_by_user);
    }

    useEffect(() => {
        if(!socketRef.current && user && user.username) {
            console.log("setting socket ref");
            socketRef.current = io("https://kentmotorclub.com:9000", {
                transports: ['websocket']
            });
        }

    }, [user]);

    useEffect(() => {
        function handleMessage(message){
            console.log("socket received message",message);
    
            try {
                
                if('chat_history' in message) {
                    processChatHistory(message.chat_history);
                } 
    
                if('recent_messages' in message) {

                    //setRecentMessages(message);

                    var messages = [];
                    var unread_count = 0;

                    var now = new Date();
                    
                    var unread_by_user = [...unreadByUser];

                    message.recent_messages.map((v,i) => {

                        //console.log(v);

                        v['date_object'] = new Date(v['created_at']);
                        v['relative_time'] = chatTimeSince(now,v['date_object']);
                        //unread_count += v['is_read'] == 0 ? 1 : 0;
                        if(v['is_read'] == 0) {
                            unread_count++;
                            if(unread_by_user[v.from_user] !== undefined) unread_by_user[v.from_user]++;
                            else unread_by_user[v.from_user] = 1;
                        }
                        
                        messages.push(v);
                        
                        
                    });

                    console.log(unread_count,messages);

                    setChatList(messages);
                    setUnreadMessages(unread_count);
                    setUnreadByUser(unread_by_user);
    
                }
                
                if('single_chat_history' in message) {                        

                    processChatHistory(message.single_chat_history,true);
            
                }
                
            } catch (e) {
                console.log(message,e);
                return;
            }
        }
    
        // BAD: this ties the state of the UI with the time of reception of the
        // 'foo' events

        if(socketRef.current && user && user.username) {
            socketRef.current.on('message', handleMessage);

            //console.log("set handlemessage");
        
            return () => {
                socketRef.current.off('message', handleMessage);
            };
        }
      }, [user, chatUsers, chatDataByUser]);

    useEffect(() => {

        function connectFunction() {
            console.log("connected",socketRef.current);

            socketRef.current.emit('message', {
                id: user.username,
                getChatHistory: 'true'
            });    
            
        }

        if(socketRef.current) {

            console.log("setting socket events");

            socketRef.current.on('error', function() {
                console.log("Sorry, there seems to be an issue with the connection!");
            });
        
            socketRef.current.on('connect_error', function(err) {
                console.log("connect failed ",err);
            });
        
            socketRef.current.on('connect', connectFunction);

            //socketRef.current.on('connection', connectFunction);
    
            return () => {
              socketRef.current.off('connect', connectFunction);
            };
        }
    }, [user]);



    /* end chat stuff */
    

    return (
        <ChatContext.Provider value={{chatUsers, chatDataByUser, chatList, unreadMessages, socketRef, closeSmChat, setChatUsers, sendChat, unreadByUser, markChatRead, uploadDocument, chatAttachmentInfo}}>
            {children}
        </ChatContext.Provider>
    );

}

export default ChatContextProvider;