import React, { useState } from 'react'
import { getImages } from '../../Componment/const'
import CommonForm from '../../Componment/Frontend/Common/CommonForm'
import ModalLayout from '../../Componment/Frontend/Common/ModalLayout'
import AboutReadMoreModal from '../../Componment/Frontend/About/AboutReadMoreModal'
const meetTeam = [
    {
        id: 0,
        name: 'Gregg Hayim',
        role: 'Role Here',
        image: getImages('Gregg_Hayim.webp'),
        msg: `For 15 years I had the privilege of sitting at the desk as General Manager of Ferrari Long Island. Along with my day to day role as the GM, I was also responsible for the ground game operations of multiple stores as the Experience Auto Group grew. From Porsche to Maserati and even Volvo, I have enjoyed virtually every day of my career. I am a car business junkie, I just love it and always have. My passion is people and helping the get the wheel of their dream car. I believe that these cars serve a very important purpose than just good looking toys. I believe they are an amazing machine for creating memories. As an absolute superstar high school athlete, my passion is creating and working with the absolute best team possible. If you don’t remember my stat line in 2000, ha…yeah you do.`
    },
    {
        id: 1,
        name: 'Vladimir Sidorenko',
        role: 'Role Here',
        image: getImages('Vladimir_Sidorenko.webp'),
        msg: `Growing up first in the Ukraine and then Israel, I didn’t have much opportunity growing up to be around these incredible machines. When I first saw a Ferrari as a kid it became my dream to one day drive one. The Loft  is a dream come true for me and its my focus is on ensuring that our inventory is stocked with nothing but blue chip cars.  I started in the business as a glorified lot attended, asked to do anything and everything for a large sized BMW store. I climbed through the ranks, including a unmemorable year owning my own dealership, and joined the experienced Auto Group in 2016. Through three stores and too many deals to count, I found my home. I am a student of markets and pride myself on my specific knowledge of all things Porsche and Ferrari, yet aside from cars I don’t have much knowledge at all, which is ironic. . I love the business but I don’t work for the money, I work because I love the people I work with. My passion is living life to the fullest and I try my best to get better at it everyday. Some people around here think i’m a communist, i’m not. But I do love red cars, so assume whatever you’d like…`
    },
    {
        id: 2,
        name: 'Cody Yaeger',
        role: 'Role Here',
        image: getImages('Vladimir_Sidorenko.webp'),
        msg: `I love cars, it's been my passion forever and I like to think i’m pretty decent at helping my clients find and buy the right car for them. I joined the Experience Auto Group five years ago and the family atmosphere won me over.  My memory for a car's unique traits, and movie’s from generations passed often leads to people mistaking me as a 65 year old Jewish man wedged into a 26 year old’s body. I genuinely enjoy what I do and the people I do it with. I love my customers, I love the people I work with and having the opportunity to do what I do on a daily basis is a privilege.`
    },
    {
        id: 3,
        name: 'Ed Hoene',
        role: 'Role Here',
        image: getImages('Vladimir_Sidorenko.webp'),
        msg: `I’ve been doing this a long time, son. I like to say this in jest because well, its true. When it comes to servicing cars, there isn’t much I haven’t seen. I began my career in…thats my business…at Denver Diesel and haven’t taken a day off since. My specialty is not fixing cars, it’s fixing people. The cars brake and usually cost a lot of money to fix, anyone who doesn’t understand that shouldn’t be buying one. But typically the clientele I have had the privilege to work with over the past decade and a half does understand this, and it’s my passion to help them through the process so they understand the value that my team delivers. I own a lot of Chevelle’s and enjoy working on these shitbox’s. I was real cool in High School and have called the Experience Auto Group home since 2010 and The Loft is a passion project that allows me to do what I do, with the people I love, around people I admire. Combustion engines and cars that come from a time when they were actually, cars- this is what I love and I don’t do electric so if you have one of those, call someone else.`
    },
    {
        id: 4,
        name: 'Jimmy Moustaki',
        role: 'Role Here',
        image: getImages('Vladimir_Sidorenko.webp'),
        msg: `Born and raised in Long Island City, I like to go fast. In a car, in a boat, painting a house or fixing your Ferrari- I go fast. But i’m also really good at what I do. Fixing cars and boosting my own ego is my passion. Eddie Hoene and I have worked together since 1987…kicking ass and taking names everyday since. I work hard and raised my family right. If you want to go slow and do things half assed, im not your guy.`
    }

]
const About = () => {
    const [aboutReadMore, setAboutReadMore] = useState(false);
    const [meetTeamDtl, setMeetTeamDtl] = useState("");
    const openReadMoreModal = (teamItem) => {
        setAboutReadMore(true);
        setMeetTeamDtl(teamItem)
    }
    const closeReadMoreModal = () => {
        setAboutReadMore(false);
    }
    let maxChars  = 457;
    return (
        <>
            <section className='about-wrap' style={{
                backgroundImage: `#161616 url(${getImages('about_banner.webp')}) no-repeat center top`,
                backgroundSize: '100%'
            }}>
                <section className='about-first-block'>
                    <div className='container'>
                        <div className='main-heading font-2-8em wow fadeInDown mb-4' data-wow-delay="0.2s">About Kent Motor Club</div>
                        <div className='cs-content text-white mt-3 wow fadeInDown' data-wow-delay="0.4s">
                            <p>We believe in a world that existed just fine before the internet ruined the fun. We negotiate, we laugh and if we like you we’ll pick up the phone. We believe electric engines are meant for feminine pleasure products and not cars.</p>

                            <p>We believe gasoline is as vital as water and view exhaust fumes as nature’s deodorant. We don’t do drama or politics. No hassles and no bullshit.</p>

                            <p>Low expenses, lots of laughs and if we had a corporate ladder, we’d stick it inside a cage and jump off it.  We don’t have one, and that’s just fine by us. We aren’t for everyone, but we are for some. We don’t list our address not because we’re lost, but because we don’t want to be found. Come find us, if you can….</p>
                        </div>
                    </div>
                </section>
                <section className='meet-team-block'>
                    <div className='container'>
                        <div className='main-heading font-2-8em text-green wow fadeInDown mb-5 mt-5 pt-5 pb-3' data-wow-delay="0.2s">Meet our team</div>
                        <div className='row g-5'>
                            {meetTeam.map((item, index) =>
                                <div className='col-md-4'>
                                    <div className='meet-team-box'>
                                        <div className='d-flex align-items-center'>
                                            <div className='mt-circle'>
                                                <img src={item.image} />
                                            </div>
                                            <div className='ps-3'>
                                                <div className='sm-title'>{item.name}</div>
                                                <div className='sm-title text-green mt-2'>{item.role}</div>
                                            </div>
                                        </div>
                                        <div className='mnh-340 mt-3'>
                                            <p className='text-white'>{item.msg.length > maxChars ? item.msg.slice(0, maxChars) + '...' : item.msg}</p>
                                        </div>
                                        <div className='text-center mt-4'>
                                            <button type='button' onClick={()=>openReadMoreModal(item)} className='green-btn font-1-1em mnw-220'>Read More</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* <div className='col-md-4'>
                                <div className='meet-team-box'>
                                    <div className='d-flex align-items-center'>
                                        <div className='mt-circle'>
                                            <img src={getImages('Vladimir_Sidorenko.webp')} />
                                        </div>
                                        <div className='ps-3'>
                                            <div className='sm-title'>Vladimir Sidorenko</div>
                                            <div className='sm-title text-green mt-2'>Role Here</div>
                                        </div>
                                    </div>
                                    <div className='mnh-340 mt-3'>
                                        <p className='text-white'>Came to the states in the year 2000…I came to America in 2000 by way of Israel and before that the Ukraine. I didn’t have much growing up and the opportunity to be around these incredible machines, and people, is a dream come true. I started in the business as a glorified lot attended, asked to do anything and everything for a large sized BMW store. I climbed through the ranks, including a unmemorable year owning my own dealership, and joined the experienced…</p>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button type='button' onClick={openReadMoreModal} className='green-btn font-1-1em mnw-220'>Read More</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='meet-team-box'>
                                    <div className='d-flex align-items-center'>
                                        <div className='mt-circle'>
                                            <img src={getImages('Vladimir_Sidorenko.webp')} />
                                        </div>
                                        <div className='ps-3'>
                                            <div className='sm-title'>Cody Yaeger</div>
                                            <div className='sm-title text-green mt-2'>Role Here</div>
                                        </div>
                                    </div>
                                    <div className='mnh-340 mt-3'>
                                        <p className='text-white'>I love cars, it's been my passion forever and I like to think i’m pretty decent at helping my clients find and buy the right car for them. I joined the Experience Auto Group five years ago and the family atmosphere won me over.  My memory for a car's unique traits, and movie’s from generations passed often leads to people mistaking me as a 65 year old Jewish man wedged into a 26 year old’s body. I genuinely enjoy what I do, and the people I do it with. I love my customers…</p>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button type='button' onClick={openReadMoreModal} className='green-btn font-1-1em mnw-220'>Read More</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='meet-team-box'>
                                    <div className='d-flex align-items-center'>
                                        <div className='mt-circle'>
                                            <img src={getImages('Vladimir_Sidorenko.webp')} />
                                        </div>
                                        <div className='ps-3'>
                                            <div className='sm-title'>Ed Hoene </div>
                                            <div className='sm-title text-green mt-2'>Role Here</div>
                                        </div>
                                    </div>
                                    <div className='mnh-340 mt-3'>
                                        <p className='text-white'>I’ve been doing this a long time, son. I like to say this in jest because well, its true. When it comes to servicing cars, there isn’t much I haven’t seen. I began my career in…thats my business…at Denver Diesel and haven’t taken a day off since. My specialty is not fixing cars, it’s fixing people. The cars brake and usually cost a lot of money to fix, anyone who doesn’t understand that shouldn’t be buying one. But typically the clientele I have had the privelge to work with over the past…</p>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button type='button' onClick={openReadMoreModal} className='green-btn font-1-1em mnw-220'>Read More</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='meet-team-box'>
                                    <div className='d-flex align-items-center'>
                                        <div className='mt-circle'>
                                            <img src={getImages('Vladimir_Sidorenko.webp')} />
                                        </div>
                                        <div className='ps-3'>
                                            <div className='sm-title'>Jimmy Moustakis</div>
                                            <div className='sm-title text-green mt-2'>Role Here</div>
                                        </div>
                                    </div>
                                    <div className='mnh-340 mt-3'>
                                        <p className='text-white'>Born and raised in Long Island City, I like to go fast. In a car, in a boat, painting a house or fixing your Ferrari- I go fast. But i’m also really good at what I do. Fixing cars and boosting my own ego is my passion. Eddie Hoene and I have worked together since 1987…kicking ass and taking names everyday since. I work hard and raised my family right. If you want to go slow and do things half assed, im not your guy. </p>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button type='button' onClick={openReadMoreModal} className='green-btn font-1-1em mnw-220'>Read More</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </section>
            <section className='drop-line-wrap'
                style={{
                    backgroundImage: `url(${getImages('become-member.webp')}) no-repeat center center`,
                    backgroundSize: 'cover'
                }}
            >
                <div className='container mxw-980'>
                    <div className='main-heading mb-3 text-uppercase text-center'>Drop Us a Line</div>
                    <p className='text-center font-1-3em text-white'>Call or Text us at 800-345-6789</p>
                    <CommonForm />
                </div>
            </section>
            <ModalLayout open={aboutReadMore} close={closeReadMoreModal} darkThemeCls='true' modalWidth={750}>
                <AboutReadMoreModal close={closeReadMoreModal} meetTeamDtl={meetTeamDtl} />
            </ModalLayout>
        </>
    )
}

export default About