import React, {useEffect, useState} from 'react'

const DealerEditModal = ({close,auctionData,updateAuction}) => {

    const [formData, setFormData] = useState({
        avg_price: "",
        description: ""
    });

    const [dataSet,setDataSet] = useState(false);

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        });
    }

    const submitForm = async (e) => {
        e.preventDefault();

        console.log(formData);

        updateAuction(formData);    
        
        close();
    }

    useEffect(() => {
        if(auctionData && !dataSet) {
            console.log("dealereditmodal useEffect",dataSet,formData);
            setFormData({
                avg_price:auctionData ? auctionData.avg_price : "1234",
                description:auctionData && auctionData.seller_notes_array.length > 0 ? auctionData.seller_notes_array[0].replaceAll('<br />', '\r\n') : "test"
            });
            setDataSet(true);
        }
    },[auctionData]);

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title text-uppercase">
                        Dealer Edit
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body px-4 py-4">
                    <form className="service-from form-horizontal" onSubmit={submitForm}>
                        <div className="form-group mb-3">
                            <label className="control-label">AVG RETAIL PRICE</label>
                            <div className="">
                                <input type="text" className="form-control" name="avg_price" value={formData.avg_price} required onChange={onChangeInput}/>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label">DESCRIPTION</label>
                            <div className="">
                                <textarea className="form-control h-120" name="description" rows={5} value={formData.description} required onChange={onChangeInput}></textarea>
                            </div>
                        </div>
                        <div className="register-btn mt-4">
                            <button type="submit" className="green-btn w-100 lg-btn">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default DealerEditModal