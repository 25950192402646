import LazyLoad from 'react-lazyload';
export const getImages = (imageName) => {
    const image = require(`../assets/img/${imageName}`);
    return image;
}

export const LazyImage = ({ src, alt }) => {
    return (
        <LazyLoad height={200} offset={100}>
            <img src={src} alt={alt} />
        </LazyLoad>
    );
};

export const LazyBackground = ({ src, children, className, id }) => {
    return (
        <LazyLoad>
            <div
                id={id}
                className={className}
                style={{
                    background: `#000 url(${src}) no-repeat center center`
                }}
            >
                {children}
            </div>
        </LazyLoad>
    );
};