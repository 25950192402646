import React, { useState, useContext } from 'react'
import { UserContext } from '../../Context/UserContext';

const RegisterModal = ({ close }) => {
    const [toggleAdvanced, setToggleAdvanced] = useState(false);
    const toggleAdvancedInfo = () => {
        setToggleAdvanced(!toggleAdvanced);
    }

    const {addUser} = useContext(UserContext);

    const [cardRequired,setCardRequired] = useState(false);
    const [termsAccepted,setTermsAccepted] = useState(false);

    const [formData, setFormData] = useState({
        username:'',
        email:'',
        password:'',
        password_confirm:'',
        first_name:'',
        last_name:'',
        include_card:'',
        billing_name:'',
        zip:'',
        phone:'',
        card_number:'',
        card_expiration_month:'',
        card_expiration_year:'',
        cvc:'',
        terms:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        });

        if(e.target.name == "include_card") setCardRequired(e.target.checked);

        if(e.target.name == "terms") setTermsAccepted(e.target.checked);
    }
    const onCardNumberInput = (e) => {
        const input = e.target;
        const formattedValue = input.value.replace(/\D/g, ''); // Remove non-numeric characters
        setFormData({
            ...formData,
            card_number: formattedValue
        });
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if(termsAccepted) {
            console.log(formData);

            const data = await addUser(formData);

            if(data && 'message' in data && data.message != "") alert(data.message.replaceAll('\\n','\n'));

            if(data && 'success' in data && data.success == "1") close();
        } else
            alert("Must accept terms and conditions");

    }

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title">
                        Register
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body px-4 py-4">
                    <form className="service-from form-horizontal" onSubmit={submitForm}>
                        <div className="form-group mb-3">
                            <label className="control-label ">Username</label>
                            <div className="">
                                <input type="text" className="form-control" name="username" required onChange={onChangeInput}/>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label ">Email Address</label>
                            <div className="">
                                <input type="email" className="form-control" name="email" required onChange={onChangeInput}/>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label ">Password</label>
                            <div className="">
                                <input type="password" className="form-control" name="password" autoComplete="new-password"  required onChange={onChangeInput}/>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label ">Verify Password</label>
                            <div className="">
                                <input type="password" className="form-control" name="password_confirm" required onChange={onChangeInput}/>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label ">First Name</label>
                            <div className="">
                                <input type="text" className="form-control" name="first_name" required onChange={onChangeInput}/>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label ">Last Name</label>
                            <div className="">
                                <input type="text" className="form-control" name="last_name" required onChange={onChangeInput}/>
                            </div>
                        </div>
                        <div className="cc_info">
                            <div className='form-group mt-10'>
                                <div className='p-3 card'>
                                    <div className="checkbox-form-group">
                                        {/* <div className="custom-checkbox mb-1">
                                            <input className="styled-checkbox" type="checkbox" name="include_card" id="toggle_cc_info" value="yes" 
                                            onChange={(e) => {
                                                onChangeInput(e);
                                                toggleAdvancedInfo(e);
                                            }}/>
                                            <label htmlFor="toggle_cc_info">
                                                Want to Bid on Auctions (optional)
                                            </label>
                                        </div> */}
                                        <div className="checkbox mb-1">
                                            <label>
                                                <input id="toggle_cc_info" type="checkbox" name="include_card" value="yes" 
                                                    onChange={(e) => {
                                                        onChangeInput(e);
                                                        toggleAdvancedInfo(e);
                                                    }}
                                                /> Want to Bid on Auctions (optional)
                                            </label>
                                        </div>
                                    </div>
                                    <div className="why">We need a credit card on file in order to lorem ipsum</div>
                                </div>
                            </div>
                            <div id="register_cc_info" className={`cc_form_fields ${toggleAdvanced ? "": "d-none"}`}>
                                <div className="form-group mb-3">
                                    <label className="control-label">BILLING NAME</label>
                                    <div>
                                        <input type="text" className="form-control cc-field" name="billing_name" required={cardRequired} onChange={onChangeInput}/>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="control-label">ZIP CODE</label>
                                    <div>
                                        <input type="text" className="form-control cc-field" name="zip" required={cardRequired} onChange={onChangeInput}/>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="control-label">PHONE NUMBER</label>
                                    <div>
                                        <input type="tel" className="form-control cc-field" name="phone" required={cardRequired} onChange={onChangeInput}/>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="control-label">CREDIT CARD #</label>
                                    <div>
                                        <input 
                                            type="text"
                                            className="form-control cc-field"
                                            name="card_number"
                                            required={cardRequired}
                                            value={formData.card_number}
                                            inputMode="numeric"
                                            onChange={onCardNumberInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className='row'>
                                        <label className="control-label col-12">EXP. DATE</label>
                                        <div className='col-6'>
                                            <div>
                                                <select className="form-control cc-field" name="card_expiration_month" required={cardRequired} onChange={onChangeInput}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div>
                                                <select className="form-control cc-field" name="card_expiration_year" required={cardRequired} onChange={onChangeInput}>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="control-label col-sm-4">SECURITY CODE</label>
                                    <div>
                                        <input type="text" className="form-control cc-field" name="cvc" required={cardRequired} onChange={onChangeInput}/>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="cc-icons col-md-offset-4 col-md-4 col-6 text-end"></div>
                                    <div className="norton-badge col-md-3 col-6 text-end"></div>
                                </div>
                            </div>
                        </div>
                        <div className="register-terms form-group">
                            {/* <div className="custom-checkbox mb-1">
                                <input className="styled-checkbox" type="checkbox" id="t&c" name="terms" value="yes" onChange={onChangeInput}/>
                                <label htmlFor="t&c">
                                    I accept the  <a href="#">Terms &amp; Condistions</a> and  <a href="#">Privacy Policy</a>
                                </label>
                            </div> */}
                            <div className="checkbox mb-1">
                                <label>
                                    <input id="tnc" type="checkbox"
                                        onChange={onChangeInput}
                                        name="terms"
                                    /> I accept the  <a href="#">Terms &amp; Condistions</a> and  <a href="#">Privacy Policy</a>
                                </label>
                            </div>
                        </div>
                        <div className="register-btn">
                            <button type="submit" className="green-btn w-100 lg-btn">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default RegisterModal