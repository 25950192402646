import React, { useState } from 'react'
import ChatSearchBox from './ChatSearchBox';
import { useLocation } from 'react-router-dom';
import { getImages } from '../../const';

const ChatBoxHeader = ({chatHeader, backArrowBtn, groupChatNames}) => {

    const {pathname} = useLocation();
    const [openSearch, setOpenSearch] = useState(false);
    const openSearchBox = () => {
        setOpenSearch(true)
    }
    const closeSearchBox = () => {
        setOpenSearch(false)
    }
    const moreCount = groupChatNames?.length - 4
    return (
        <> 
            <div className="msg-head d-flex align-items-center justify-content-between">
                <div className='white-back-arrow cursor-pointer d-md-none' onClick={backArrowBtn}>
                    <img src={getImages('white-back-arrow.webp')}/>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='chat-xs-title me-3 d-none d-md-block'>TO</div>
                    <div className='chat-sm-title text-white fw-900 me-3 mb-0'>{chatHeader}</div>
                     {/* {pathname === "/chat-group" ? "" : <div className='chat-sm-title text-white fw-900 me-3'>{chatData?.name || ""}</div>}  */}
                    {groupChatNames ?  
                        <div className='chat-sm-title'>
                            {groupChatNames?.slice(0, 3).map((item, index)=>
                                <span key={index}>
                                    {item}
                                    {index !== groupChatNames?.length - 1 && ', '}&nbsp;
                                </span>
                            )} {moreCount >= 0 ? <>+ {moreCount + 1}</> : ""}
                        </div>
                    : ""
                    }
                </div>
                <div className='d-flex align-items-center'>
                    <button type='button' className='chat-search-icon me-4' onClick={openSearchBox}></button>
                    <div className="btn-group chat-dropdown chatmenu-dropdown">
                        <button type='button' data-bs-toggle="dropdown" aria-expanded="false" className='optionmenu-icon'></button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><button className="dropdown-item" type="button">Clear Messages</button></li>
                            <li><button className="dropdown-item" type="button">Delete Chat</button></li>
                            <li><button className="dropdown-item" type="button">View All Media</button></li>
                            <li><button className="dropdown-item" type="button">Exit Group</button></li>
                        </ul>
                    </div>
                </div>
            </div>
            {openSearch ? 
            <>
                <ChatSearchBox 
                    openSearch={openSearch}
                    closeSearchBox={closeSearchBox}/>
            </> : ""}
        </>
    )
}

export default ChatBoxHeader