import React, { useState, useContext, useEffect, useRef } from 'react'
import { getImages } from '../../Componment/const'
import fullImage from '../../assets/img/hero-img.webp'
import thumbImg1 from '../../assets/img/vdp-thumb2.webp'
import thumbImg2 from '../../assets/img/vdp-thumb3.webp'
import thumbImg3 from '../../assets/img/vdp-thumb4.webp'
import thumbImg4 from '../../assets/img/vdp-thumb5.webp'
import Breadcrumb from '../../Componment/Frontend/Common/Breadcrumb'
import Fancybox from '../../Componment/Frontend/Common/Fancybox'
import ModalLayout from '../../Componment/Frontend/Common/ModalLayout'
import RegisterModal from '../../Componment/Frontend/Auction/RegisterModal'
import LoginModal from '../../Componment/Frontend/Auction/LoginModal'
import ForgotPassword from '../../Componment/Frontend/Auction/ForgotPassword'
import MarketAnalysis from '../../Componment/Frontend/Auction/MarketAnalysis'
import AskDealerQuestion from '../../Componment/Frontend/Auction/AskDealerQuestion'
import AddToWishlist from '../../Componment/Frontend/Auction/AddToWishlist'
import AskAQuestion from '../../Componment/Frontend/Auction/AskAQuestion'
import DealerEditModal from '../../Componment/Frontend/Auction/DealerEditModal'
import { AuctionContext } from '../../Componment/Context/AuctionContext'
import { VehicleContext } from '../../Componment/Context/VehicleContext'
import Timer from '../../Componment/Frontend/Common/Timer'

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import OwlCarousel from "react-owl-carousel";

import { UserContext } from '../../Componment/Context/UserContext'
import BidModal from '../../Componment/Frontend/Auction/BidModal'
import ChatModal from '../../Componment/Frontend/Auction/ChatModal'

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
const auctionList = [
    {
        images: [getImages('removebg-preview.webp')],
        vehicle_name: '2011 FERRARI 599 GTO',
        price: '$80,000'
    },
    {
        images: [getImages('ferrari-img.webp')],
        vehicle_name: '2023 Porsche 911 Carrera T',
        price: '$280k'
    },
    {
        images: [getImages('hero-img.webp')],
        vehicle_name: '2022 Porsche Macan Turbo',
        price: '$145k'
    },
    {
        images: [getImages('removebg-preview.webp')],
        vehicle_name: '2011 FERRARI 599 GTO',
        price: '$80,000'
    },
    {
        images: [getImages('ferrari-img.webp')],
        vehicle_name: '2023 Porsche 911 Carrera T',
        price: '$280k'
    },
]
const Auction = () => {

    const queryParameters = new URLSearchParams(window.location.search);
    const vehicleVIN = queryParameters.get("vin");

    const { getAuctionDetails, updateAuctionData } = useContext(AuctionContext);
    const { numberFormatter, priceFormatter } = useContext(VehicleContext);

    const [auctionData, setAuctionData] = useState(null);

    const { user } = useContext(UserContext);


    const getAuction = async (vin) => {
        var auction_data = await getAuctionDetails(vin);
        console.log("auction.js getAuction", vin, auction_data);
        setAuctionData(auction_data);

        setIMGs(auction_data.image_urls_array);
    }

    const updateAuction = async (updateData) => {
        const data = await updateAuctionData(auctionData.vin,updateData);

        if(data && 'success' in data && data.success == "1") {
            setAuctionData({
                ...auctionData,                
                    "avg_price":updateData.avg_price,
                    "seller_notes_array":[updateData.description]
                
            });
        }
    }

    useEffect(() => {
        if (vehicleVIN) {
            getAuction(vehicleVIN);
        }
    }, [vehicleVIN]);


    const [imgs, setIMGs] = useState([
        fullImage,
        thumbImg1,
        thumbImg2,
        thumbImg3,
        thumbImg4
    ]);

    const moreImg = imgs.length - 4;
    // Login Modal
    const [loginModal, setLoginModal] = useState(false);
    const handleLoginModal = () => {
        setLoginModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeLoginModal = () => {
        setLoginModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Register Modal
    const [registerModal, setRegisterModal] = useState(false);
    const handleRegisterModal = () => {
        setRegisterModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeRegisterModal = () => {
        setRegisterModal(false);
        document.body.classList.remove('overflow-body');
    }
    // ForgotPwd Modal
    const [forgotPwdModal, setForgotPwdModal] = useState(false);
    const handleForgotPwdModal = () => {
        setForgotPwdModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeForgotPwdModal = () => {
        setForgotPwdModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Market Analysis Modal
    const [marketAnalysisModal, setMarketAnalysisModal] = useState(false);
    const handleMarketAnalysisModal = () => {
        setMarketAnalysisModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeMarketAnalysisModal = () => {
        setMarketAnalysisModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Ask Dealer Question Modal
    const [askQuestionModal, setAskQuestionModal] = useState(false);
    const handleAskQuestionModal = () => {
        setAskQuestionModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeAskQuestionModal = () => {
        setAskQuestionModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Add Wishlist Modal
    const [addWishlistModal, setAddWishlistModal] = useState(false);
    const handleAddWishlistModal = () => {
        setAddWishlistModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeAddWishlistModal = () => {
        setAddWishlistModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Vehicle Detail Modal
    const [openVehicleDetail, setOpenVehicleDetail] = useState(false);
    const handleVehicleDetail = () => {
        setOpenVehicleDetail(true);
        document.body.classList.add('overflow-body');
    }
    const closeVehicleDetail = () => {
        setOpenVehicleDetail(false);
        document.body.classList.remove('overflow-body');
    }
    // Vehicle Detail Modal
    const [openConditionReport, setOpenConditionReport] = useState(false);
    const handleConditionReport = () => {
        setOpenConditionReport(true);
        document.body.classList.add('overflow-body');
    }
    const closeConditionReport = () => {
        setOpenConditionReport(false);
        document.body.classList.remove('overflow-body');
    }
    // Bid History Modal
    const [openBidHistory, setOpenBidHistory] = useState(false);
    const handleBidHistory = () => {
        setOpenBidHistory(true);
        document.body.classList.add('overflow-body');
    }
    const closeBidHistory = () => {
        setOpenBidHistory(false);
        document.body.classList.remove('overflow-body');
    }
    const [openDealerEdit, setOpenDealerEdit] = useState(false)
    const handleDealerEdit = () => {
        setOpenDealerEdit(true)
    }
    const closeDealerEdit = () => {
        setOpenDealerEdit(false)
    }
    // const options = {
    //     animateOut: 'fadeOut',
    //     animateIn: 'fadeIn',
    //     autoplay:true,
    //     autoplayTimeout:2000,
    //     autoplayHoverPause:true,
    //     responsive: {
    //         0: {
    //             items: 1,
    //             margin: 0
    //         }
    //     },
    // };
    const carouselRef = useRef(null);
    const [expandAction, setExpandAction] = useState(false);
    const handleLiveAction = () => {
        setExpandAction(!expandAction)
    }

    const conditionQuestions = {
        'paintwork':'ANY PAINTWORK?',
        'cosmetic_dents':'COSMETIC DENTS OR DINGS?',
        'frame_damage':'FRAME/ UNIBODY DAMAGE?',
        'rust':'ANY RUST?',
        'wheel_scuffs':'WHEEL SCUFFS OR PITTING?',
        'tires_front':'TIRE DEPTH LESS THAN 60%- FRONT?',
        'tires_back':'TIRE DEPTH LESS THAN 60%- REAR?',
        'windshield_damage':'IS THERE ANY DAMAGE OR MARKINGS ON THE WINDSHIELD OR WINDOWS?',
        'exterior_recon':'ANY OTHER EXTERIOR RECON NEEDED?',
        'interior_recon':'LEATHER/INTERIOR REPAIRS NEEDED?',
        'interior_odor':'INTERIOR CIGARETTE/CIGAR ODOR?',
        'sticky_interior':'STICKY INTERIOR ELEMENTS?',
        'stereo_speakers_inoperable':'STEREO SPEAKERS INOPERABLE?',
        'aftermarket_acessories':'AFTERMARKET ACCESSORIES INSTALLED?',
        'dashboard_peeling':'DASHBOARD PULLING/ PEELING?',
        'other_interior_recon':'ANY OTHER INTERIOR RECON NEEDED?',
        'starting_issues':'STARTING/RUNNING ISSUES?',
        'transmission_issues':'TRANSMISSION OR GEAR SHIFTING ISSUES?',
        'engine_light':'CHECK ENGINE LIGHT PRESENT?',
        'fault_codes':'ANY FAULT CODES PRESENT?',
        'engine_noise':'ENGINE NOISE OR KNOCK HEARD?',
        'mechl_aftermarket_accesories':'AFTERMARKET ACCESSORIES INSTALLED?',
        'mechanical_issues':'ANY OTHER KNOWN MECHANICAL ISSUES?',
        'title_status':'HAS LIEN?',
        'salvage':'SALVAGE?',
        'mileage_known':'TRUE MILEAGE UNKNOWN?',
        'title_absent':'TITLE ABSENT (3 WEEKS)?',
        'carfax_issues':'CARFAX ISSUES PRESENT? ',
        'accidents':'ANY KNOWN ACCIDENTS?',
        'keys':'MISSING KEYS?',
        'book_manual':'MISSING BOOKS OR MANUALS?',
        'aftermarket_accesories':'AFTER-MARKET ACCESSORIES INSTALLED?'
    }

    const conditionGoodBad = [
        'tires_front','tires_back','title_status'
    ];

    const conditionBool = [
        'keys','book_manual'
    ]

    const getConditionSection = (value) => {

        var condition_text = '';

        if(!auctionData) 
            condition_text = "no"
        else if(conditionGoodBad.includes(value)) 
            condition_text = auctionData[value] == "bad" ? "yes" : "no";
        else if(conditionBool.includes(value)) 
            condition_text = auctionData[value] ? "no" : "yes";
        else 
            condition_text = auctionData[value];

		return (
            <>
                <tr>
                    <td>{conditionQuestions[value]}</td>
                    <td className={'text-uppercase' + (condition_text == "yes" ? " red" : "")}>{condition_text}</td>
                </tr>
                {auctionData && auctionData[value + "_text"] ? <tr className="option-answer-tr"><td className='text-uppercase'>{auctionData[value + "_text"]}</td></tr> : ""}
            </>
        );
	}
    
    // Bid Modal
    const [openBidModal, setOpenBidModal] = useState(false);
    const handleBidModal = () => {
        setOpenBidModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeBidModal = () => {
        setOpenBidModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Chat Modal
    const [openChatModal, setOpenChatModal] = useState(false);
    const handleChatModal = () => {
        setOpenChatModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeChatModal = () => {
        setOpenChatModal(false);
        document.body.classList.remove('overflow-body');
    }
    
    return (
        <>
            <section className='vdp-main' style={{
                backgroundImage: `#161616 url(${getImages('hero-bg.webp')}) no-repeat center top`,
                backgroundSize: '100%'
            }}>
                <section className='auction-content py-5'>
                    <div className='container'>
                        <div className='d-flex align-items-center justify-content-between mb-5 breadcrumb-flex'>
                            <div>
                                <Breadcrumb marginBottom={'0'} clsName={'aution-breadcrumb'} />
                            </div>
                            <div>
                                {user && user.admin ? <button className='green-btn rounded-3 fw-500' onClick={handleDealerEdit}>DEALER EDIT</button> : <></>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-8 col-12 pe-5'>
                                <div className='gray-box p-4 mb-4'>
                                    <div className='d-flex align-items-center justify-content-between mb-4 auction-header-flex'>
                                        <div>
                                            <div className='vdp-md-title'>{auctionData ? auctionData.year + " " + auctionData.make + " " + auctionData.model + " " + auctionData.trim : "2021 Ferrari 812 GTS"}</div>
                                        </div>
                                        <div className='text-center'>
                                            <div className='d-inline-flex align-items-center'>
                                                <span className='xs-title pe-2'>High Bid</span>
                                                <span className='vdp-md-title'>{auctionData && auctionData.max_bid ? priceFormatter(auctionData.max_bid, true) : "-"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: false,
                                            },
                                        }}
                                    >
                                        <div className="d-flex flex-wrap mt-2">
                                            <div className="col-12 full-photo photo-full-width white-bg position-relative wow zoomIn" data-wow-delay="0.2s">
                                                <div className="text-overlay px-3">
                                                    <img style={{ maxWidth: '20px' }} alt="path" src={getImages('arrow-down.webp')} />
                                                    <span className="text-overlay-numbers">{auctionData ? numberFormatter(auctionData.retail_discount_min,true) + "-" + numberFormatter(auctionData.retail_discount_max,true) : "$3k-$4.2k"}</span>
                                                    <span className="text-overlay-text pe-0">OFF Avg. Retail</span>
                                                </div>
                                                <a data-fancybox="gallery" href={imgs[0]} className='position-relative'>
                                                    <img className='w-100' src={imgs[0]} alt="th img" />
                                                </a>
                                                <div className="btn-overlay">
                                                    <button onClick={handleAddWishlistModal} type='button'><img className='me-1' style={{ verticalAlign: 'middle' }} src={getImages('view.webp')} /> ADD TO WATCHLIST</button>
                                                </div>
                                            </div>
                                            <div className='d-flex photos-list'>
                                                {imgs &&
                                                    imgs.slice(1, 5).map((item, index) => {
                                                        return (
                                                            <div className="col-3 wow zoomIn" data-wow-delay="0.4s" key={index}>
                                                                <a data-fancybox="gallery" href={item} className='position-relative'>
                                                                    <img src={item} alt="th img" />
                                                                    {/* {index >= 3 ? <div className='countimg'>+{moreImg}</div> : ""} */}
                                                                </a>
                                                            </div>
                                                        );
                                                    })}
                                                {/* Hidden links to include all images in the Fancybox gallery */}
                                                {imgs && imgs.slice(5).map((item, index) => (
                                                    <a key={index + 5} data-fancybox="gallery" href={item} style={{ display: 'none' }} />
                                                ))}
                                            </div>
                                        </div>
                                    </Fancybox>
                                </div>
                                <div className={`vdpa-box p-4 mb-4 d-md-block mob-vdpa-modal ${openConditionReport ? 'open' : 'd-none'} d-md-block`}>
                                    <div className="modal-header d-md-none">
                                        <h1 className="modal-title">Condition Report</h1>
                                        <button className="sm-box-close" type="button" onClick={closeConditionReport}><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                    <div className='vdpa-modal-body'>
                                        <div className='auction-tabs'>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="Exterior-tab" data-bs-toggle="tab" data-bs-target="#Exterior" type="button" role="tab" aria-controls="Exterior" aria-selected="false">Exterior</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="Interior-tab" data-bs-toggle="tab" data-bs-target="#Interior" type="button" role="tab" aria-controls="Interior" aria-selected="false">Interior</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="Mechanical-tab" data-bs-toggle="tab" data-bs-target="#Mechanical" type="button" role="tab" aria-controls="Mechanical" aria-selected="false">Mechanical</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="Health-Details-tab" data-bs-toggle="tab" data-bs-target="#Health-Details" type="button" role="tab" aria-controls="Mechanical" aria-selected="false">Health & Details </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="Seller-tab" data-bs-toggle="tab" data-bs-target="#Seller" type="button" role="tab" aria-controls="Mechanical" aria-selected="true">Seller </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade" id="Exterior" role="tabpanel" aria-labelledby="Exterior-tab">
                                                    <div className="tab-title d-md-none">Exterior</div>
                                                    <table className='w-100 auction-table'>
                                                        <tbody>
                                                            {getConditionSection('paintwork')}
                                                            {getConditionSection('cosmetic_dents')}
                                                            {getConditionSection('frame_damage')}
                                                            {getConditionSection('rust')}
                                                            {getConditionSection('wheel_scuffs')}
                                                            {getConditionSection('tires_front')}
                                                            {getConditionSection('tires_back')}
                                                            {getConditionSection('windshield_damage')}
                                                            {getConditionSection('exterior_recon')}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="tab-pane fade" id="Interior" role="tabpanel" aria-labelledby="Interior-tab">
                                                    <div className="tab-title d-md-none">Interior</div>
                                                    <table className='w-100 auction-table'>
                                                        <tbody>
                                                            {getConditionSection('interior_recon')}
                                                            {getConditionSection('interior_odor')}
                                                            {getConditionSection('sticky_interior')}
                                                            {getConditionSection('stereo_speakers_inoperable')}
                                                            {getConditionSection('aftermarket_acessories')}
                                                            {getConditionSection('dashboard_peeling')}
                                                            {getConditionSection('other_interior_recon')}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="tab-pane fade" id="Mechanical" role="tabpanel" aria-labelledby="Mechanical-tab">
                                                    <div className="tab-title d-md-none">mechanical</div>
                                                    <table className='w-100 auction-table'>
                                                        <tbody>
                                                            {getConditionSection('starting_issues')}
                                                            {getConditionSection('transmission_issues')}
                                                            {getConditionSection('engine_light')}
                                                            {getConditionSection('fault_codes')}
                                                            {getConditionSection('engine_noise')}
                                                            {getConditionSection('mechanical_issues')}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="tab-pane fade" id="Health-Details" role="tabpanel" aria-labelledby="Health-Details-tab">
                                                    <div className="tab-title d-md-none">Health &amp; Details</div>
                                                    <table className='w-100 auction-table'>
                                                        <tbody>
                                                            {getConditionSection('title_status')}
                                                            {getConditionSection('salvage')}
                                                            {getConditionSection('mileage_known')}
                                                            {getConditionSection('title_absent')}
                                                            {getConditionSection('carfax_issues')}
                                                            {getConditionSection('accidents')}
                                                            {getConditionSection('keys')}
                                                            {getConditionSection('book_manual')}
                                                            {getConditionSection('aftermarket_accesories')}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="tab-pane fade show active seller-tab-content" id="Seller" role="tabpanel" aria-labelledby="Seller-tab">
                                                    <div className="row seller-info" id="seller-info-div">
                                                        <div className="col-sm-5 seller-info wow fadeInLeft" data-wow-delay="0.2s">
                                                            <div className="md-title mb-4">DoubleClutch</div>
                                                            <address>
                                                                <p>{auctionData ? auctionData.agent_info.full_name : "Vladimir Sidorenko"}</p>
                                                                <p>{auctionData ? auctionData.agent_dealership_info.address : "65 S Service Rd"}</p>
                                                                <p>{auctionData ? auctionData.agent_dealership_info.phone : "917-224-7474"}</p>
                                                                <p><a href={"mailto:" + (auctionData ? auctionData.agent_dealership_info.email : "vsidorenko@ferrarili.com")}>{auctionData ? auctionData.agent_dealership_info.email : "vsidorenko@ferrarili.com"}</a></p>
                                                            </address>
                                                            <div className="seller-buttons-box mt-4">
                                                                <a className="green-btn w-100 md-btn" href="/srp?dealer_inventory">SEE DEALER SPECIALS</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-7 seller-info__second-block wow fadeInRight" data-wow-delay="0.4s">
                                                            {/* <div className="col-sm-12 hide">
                                                                    <div className="col-sm-offset-1 col-sm-5 seller-info-text">Average Retail:</div>
                                                                    <div className="col-sm-offset-1 col-sm-5 seller-info-value">$73,227</div>
                                                                </div>
                                                                <div className="col-sm-12 hide">
                                                                    <div className="col-sm-offset-1 col-sm-5 seller-info-text">Reserve Discount:<i className="fa fa-info-circle"></i></div>
                                                                    <div className="col-sm-offset-1 col-sm-5 seller-info-value">$3k - $4.2k</div>
                                                                </div> */}
                                                            <div className="seller-graphs col-sm-12">
                                                                <div className="row mt-4">
                                                                    <div className="col-6">
                                                                        <div className="title">
                                                                            Avg Retail Price
                                                                            <span className="tooltip-btn ms-2" data-toggle="tooltip">
                                                                                <span className='info-icon'></span>
                                                                                <div className="tooltip-hover" style={{ textAlign: "left" }}>
                                                                                    <b className="black">Avg Retail Price</b> - This metric is the average listing price of vehicles published throughout the country.
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                        <div className="seller-circle-box">
                                                                            <div className="value-par">{numberFormatter(auctionData ? auctionData.avg_price : 73000, true)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 border-l">
                                                                        <div className="title">
                                                                            Avg Mileage
                                                                            <span className="tooltip-btn ms-2" data-toggle="tooltip">
                                                                                <span className='info-icon'></span>
                                                                                <div className="tooltip-hover" style={{ textAlign: "left" }}>
                                                                                    <b>Avg Mileage</b> - this value is taken from all of the available vehicles on the market with the same Year, Make, Model &amp; Trim.
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                        <div className="seller-circle-box">
                                                                            <div className="value-par">{numberFormatter(auctionData ? auctionData.appraisal_data.averages.miles : 22000)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`vdpa-box p-4 mb-4 mob-vdpa-modal ${openVehicleDetail ? 'open' : 'd-none'} d-md-block`}>
                                    <div className="modal-header d-md-none">
                                        <h1 className="modal-title">Vehicle Details</h1>
                                        <button className="sm-box-close" type="button" onClick={closeVehicleDetail}><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                    <div className='vdpa-modal-body'>
                                        <div className='md-title mb-3 wow fadeInUp' data-wow-delay="0.2s">ABOUT THE VEHICLE</div>
                                        <div className='wow fadeInUp vdpa-about-text' data-wow-delay="0.4s">
                                        {auctionData && auctionData.seller_notes_array.length > 0 ? <p dangerouslySetInnerHTML={{__html: auctionData.seller_notes_array[0]}}/> : <>
                                            <p>Porsche 911 Carrera Manual</p>

                                            <p>Ferrari-Maserati of Long Island is proud to announce the arrival of this stunning Porsche 911 Carrera. Finished in Guards Red over Black leather, the 911 Carrera has been carefully driven by two previous owners. Expertly maintained by an Authorized Porsche Dealer, it is in excellent condition from top to bottom. The balance of the Porsche Factory Warranty is in effect.</p>

                                            <p>
                                                This Porsche 911 Carrera has been specified with:<br />
                                                - 14-Way Power Sport Seats with Memory Package ($2,320)<br />
                                                - Electric Slide/Tilt Sunroof in Glass ($1,990)<br />
                                                - Bose Surround Sound System ($1,590)<br />
                                                - 20-inch Carrera S Wheels ($1,580)<br />
                                                - Wheels Painted in Platinum Satin ($1,210)<br />
                                                - Keyless Porsche Entry & Drive ($1,090)<br />
                                                - Sport Tailpipes in Black ($950)<br />
                                                - Lane Change Assist (LCA, $850)<br />
                                                - Front Seat Ventilation ($840)<br />
                                                - Premium Package ($620)<br />
                                                - Multifunction Heated Steering Wheel ($470)<br />
                                                - Power Steering Plus ($270)<br />
                                                - Luggage Net in Passenger Footwell
                                            </p>
                                            <p>If you are in the market for a Porsche 911 Carrera, please call or email us today.</p>
                                            </>}
                                        </div>
                                    </div>
                                    <div className='d-md-block d-none'>
                                        <AskAQuestion />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className='gray-box p-4 mb-4'>
                                    <div className='d-flex align-items-center justify-content-between mb-4 wow fadeInUp' data-wow-delay="0.2s">
                                        <div className='xs-title'>Time Remaining</div>
                                        <div className='xs-title fw-700'>{auctionData ? <Timer time_to_expire={auctionData.time_to_expire} diff_seconds={auctionData.diff_seconds}/> : <></>}</div>
                                    </div>
                                    <div>
                                        {user ? <button className='green-btn lg-btn w-100 text-uppercase wow fadeInUp' onClick={handleBidModal}>Bid</button>: <button className='green-btn lg-btn w-100 text-uppercase wow fadeInUp' onClick={handleLoginModal}>Register TO Bid</button>}
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between my-4'>
                                        <div className='w-50 wow fadeInUp' data-wow-delay="0.2s">
                                            <div className='xs-title text-center'>Average Retail Price</div>
                                            <div className='vdp-md-title fw-700 text-center mt-2'>{numberFormatter(auctionData ? auctionData.avg_price : 73000, true)}</div>
                                        </div>
                                        <div className='w-50 border-l wow fadeInUp' data-wow-delay="0.4s">
                                            <div className='xs-title text-center'>Avg Mileage</div>
                                            <div className='vdp-md-title fw-700 text-center mt-2'>{numberFormatter(auctionData ? auctionData.appraisal_data.averages.miles : 22000)}</div>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='xs-title mb-2 wow fadeInUp' data-wow-delay="0.5s">Avg Retail Price</div>
                                        <div className="mmr-maheim-price">
                                            <div className="mmr-maheim-price__line wow fadeInRight" data-wow-delay="0.7s">
                                                <span className='blob'></span>
                                            </div>
                                            <div className="mmr-maheim-price__price bar">
                                                <div className='wow fadeInUp' data-wow-delay="2s">{numberFormatter(auctionData ? auctionData.avg_price : 73000, true)}</div>
                                            </div>
                                            <div className="mmr-maheim-price__our-price bar2">
                                                <div className='position-relative'><span className='price-tl wow fadeInUp' data-wow-delay="2s">Our Price</span></div>
                                                <div className='wow fadeInUp' data-wow-delay="2s">
                                                    YOU SAVE<br />
                                                    <strong>$3k - $4.2k</strong>
                                                    <div className='arrow-animation mt-3'>
                                                        <div className='arrow-icon'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='xs-title mb-2 text-center wow fadeInUp' data-wow-delay="0.4s">Reserve Proximity</div>
                                    <div className='md-title mb-0 text-center wow fadeInUp' data-wow-delay="0.6s">Getting Closer!</div>
                                </div>
                                <div className='vdpa-box p-4 mb-4'>
                                    <div className='vdpa-table'>
                                        <table className='w-100'>
                                            <tbody>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>VIN </th>
                                                    <td className='text-nowrap'><span className='copy_icon'></span>{auctionData ? auctionData.vin : "WP0AA2A98HS106979"}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Reserve Met:</th>
                                                    <td>{auctionData && auctionData.reserve_met ? "YES" : "NO"}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Miles:</th>
                                                    <td>{numberFormatter(auctionData ? auctionData.miles : 22000)}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Color:</th>
                                                    <td>{auctionData ? auctionData.color + " / " + auctionData.int_color : "Red / Black"}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Body Type:</th>
                                                    <td>{auctionData ? auctionData.body_type : "Coupe"}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Transmission:</th>
                                                    <td>{auctionData ? auctionData.transmission : "manual"}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Drivetrain:</th>
                                                    <td>{auctionData ? auctionData.drivetrain : "rear"}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Condication:</th>
                                                    <td>{auctionData ? auctionData.condition : "excellent"}</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Location:</th>
                                                    <td>{auctionData ? auctionData.location : "Plainview, NY"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='text-center mt-3'>
                                        <button className='green-btn w-100' type='button' onClick={handleMarketAnalysisModal}>MARKET ANALYSIS</button>
                                    </div>
                                    <div className='text-center mt-3'>
                                        <button className='green-btn w-100' type='button' onClick={handleAskQuestionModal}>ASK DEALER A QUESTION</button>
                                    </div>
                                    <div className='d-md-none mt-4'>
                                        <div className="md-title mb-3">DoubleClutch</div>
                                        <address>
                                            <p>Vladimir Sidorenko</p>
                                            <p>65 S Service Rd</p>
                                            <p>917-224-7474</p>
                                            <p><a href="mailto:vsidorenko@ferrarili.com">vsidorenko@ferrarili.com</a></p>
                                        </address>
                                    </div>
                                </div>
                                <div className={`vdpa-box p-4 mb-4 d-md-block mob-vdpa-modal ${openBidHistory ? 'open' : 'd-none'}`}>
                                    <div className="modal-header d-md-none">
                                        <h1 className="modal-title">Bid History</h1>
                                        <button className="sm-box-close" type="button" onClick={closeBidHistory}><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                    <div className='vdpa-modal-body'>
                                        <div className='md-title mb-3 wow fadeInUp d-none d-md-block' data-wow-delay="0.2s">BID HISTORY</div>
                                        <div className='vdpa-table bid-history-scroll'>
                                            <table className='w-100'>
                                                <tbody>
                                                    {auctionData && auctionData.all_bids.map((v, i) => {
                                                        const date = dayjs.tz(v.created_at, "UTC").fromNow();
                                                        return (
                                                            <tr className='wow fadeInUp' data-wow-delay="0.2s" key={i}>
                                                                <th>{date}</th>
                                                                <td>{numberFormatter(v.bid, true)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                        <th>6d 12h 51m 29s</th>
                                                        <td>$20,010</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobile-vdp-nav d-md-none'>
                        <ul>
                            <li className='d-flex align-items-center justify-content-between' onClick={handleVehicleDetail}>
                                <span>Vehicle Details</span>
                                <i className="fa-solid fa-chevron-right"></i>
                            </li>
                            <li className='d-flex align-items-center justify-content-between' onClick={handleConditionReport}>
                                <span>Condition Report</span>
                                <i className="fa-solid fa-chevron-right"></i>
                            </li>
                            <li className='position-relative'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <span>Photos</span>
                                    <i className="fa-solid fa-chevron-right"></i>
                                </div>
                                <Fancybox
                                    options={{
                                        Carousel: {
                                            infinite: false,
                                        },
                                    }}
                                >
                                    {imgs && imgs.map((item, index) => (
                                        <a key={index + 5} data-fancybox="galleryMob" href={item}
                                            style={{
                                                position: 'absolute',
                                                left: '0',
                                                right: '0',
                                                top: '0',
                                                bottom: '0'
                                            }}
                                        >
                                        </a>
                                    ))}
                                </Fancybox>
                            </li>
                            <li className='d-flex align-items-center justify-content-between' onClick={handleBidHistory}>
                                <span>Bid History</span>
                                <i className="fa-solid fa-chevron-right"></i>
                            </li>
                        </ul>
                    </div>
                    <div className='d-md-none px-4 mob-ask-question'>
                        <AskAQuestion />
                    </div>
                </section>
            </section>
            {loginModal &&
                <ModalLayout open={loginModal} close={closeLoginModal} modalWidth={980}>
                    <LoginModal
                        close={closeLoginModal}
                        handleRegisterModal={handleRegisterModal}
                        handleForgotPwdModal={handleForgotPwdModal}
                    />
                </ModalLayout>
            }
            {registerModal &&
                <ModalLayout open={registerModal} close={closeRegisterModal} modalWidth={680}>
                    <RegisterModal close={closeRegisterModal} />
                </ModalLayout>
            }
            {forgotPwdModal &&
                <ModalLayout open={forgotPwdModal} close={closeForgotPwdModal} modalWidth={680}>
                    <ForgotPassword close={closeForgotPwdModal} />
                </ModalLayout>
            }
            {marketAnalysisModal &&
                <ModalLayout open={marketAnalysisModal} close={closeMarketAnalysisModal} modalWidth={900}>
                    <MarketAnalysis close={closeMarketAnalysisModal} />
                </ModalLayout>
            }
            {askQuestionModal &&
                <ModalLayout open={askQuestionModal} close={closeAskQuestionModal} modalWidth={600}>
                    <AskDealerQuestion close={closeAskQuestionModal} />
                </ModalLayout>
            }
            {addWishlistModal &&
                <ModalLayout open={addWishlistModal} close={closeAddWishlistModal} modalWidth={600}>
                    <AddToWishlist close={closeAddWishlistModal} />
                </ModalLayout>
            }
            {openDealerEdit &&
                <ModalLayout open={openDealerEdit} close={closeDealerEdit} modalWidth={600}>
                    <DealerEditModal close={closeDealerEdit} auctionData={auctionData} updateAuction={updateAuction}/>
                </ModalLayout>
            }
            
            {openBidModal &&
                <ModalLayout open={openBidModal} close={closeBidModal} modalWidth={970}>
                    <BidModal close={closeBidModal} handleChatModal={handleChatModal} auctionData={auctionData} getAuction={getAuction}/>
                </ModalLayout>
            }
            
            {openChatModal &&
                <ModalLayout open={openChatModal} close={closeChatModal} modalWidth={1170}>
                    <ChatModal close={closeChatModal} />
                </ModalLayout>
            }
        </>
    )
}

export default Auction