import React, { useContext, useEffect, useRef, useState } from 'react'
import { getImages } from '../../const'
import { Link } from 'react-router-dom'
import OwlCarousel from "react-owl-carousel";
import ModalLayout from './ModalLayout';
import LoginModal from '../Auction/LoginModal';
import RegisterModal from '../Auction/RegisterModal';
import ForgotPassword from '../Auction/ForgotPassword';
import { UserContext } from '../../Context/UserContext';
import { AuctionContext } from '../../Context/AuctionContext';
import BidModal from '../Auction/BidModal';
import ChatModal from '../Auction/ChatModal';
import Timer from './Timer';
import TextContainer from './TextContainer';
import { VehicleContext } from '../../Context/VehicleContext';

const auctionList = [
    {
        images: [getImages('removebg-preview.webp')],
        vehicle_name: '2011 FERRARI 599 GTO',
        price: '$80,000'
    },
    {
        images: [getImages('ferrari-img.webp')],
        vehicle_name: '2023 Porsche 911 Carrera T',
        price: '$280k'
    },
    {
        images: [getImages('hero-img.webp')],
        vehicle_name: '2022 Porsche Macan Turbo',
        price: '$145k'
    },
    {
        images: [getImages('removebg-preview.webp')],
        vehicle_name: '2011 FERRARI 599 GTO',
        price: '$80,000'
    },
    {
        images: [getImages('ferrari-img.webp')],
        vehicle_name: '2023 Porsche 911 Carrera T',
        price: '$280k'
    },
]
const Footer = () => {

    const { user } = useContext(UserContext);
    const { getAuctionDetails, auctionData } = useContext(AuctionContext);
    const { priceFormatter } = useContext(VehicleContext);

    const optionsfl = {
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplay:false,
        autoplayTimeout:4000,
        autoplayHoverPause:true,
        responsiveClass:true,
        responsive: {
            0: {
                items: 1,
                margin: 0
            }
        },
    };
    const carouselRef = useRef(null);
    const [expandAction, setExpandAction] = useState(false);
    const handleLiveAction = () => {
        setExpandAction(!expandAction)
    }

    const [activeAuction,setActiveAuction] = useState([]);
    
    // Login Modal
    const [loginModal, setLoginModal] = useState(false);
    const handleLoginModal = () => {
        setLoginModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeLoginModal = () => {
        setLoginModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Register Modal
    const [registerModal, setRegisterModal] = useState(false);
    const handleRegisterModal = () => {
        setRegisterModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeRegisterModal = () => {
        setRegisterModal(false);
        document.body.classList.remove('overflow-body');
    }
    // ForgotPwd Modal
    const [forgotPwdModal, setForgotPwdModal] = useState(false);
    const handleForgotPwdModal = () => {
        setForgotPwdModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeForgotPwdModal = () => {
        setForgotPwdModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Bid Modal
    const [openBidModal, setOpenBidModal] = useState(false);
    const handleBidModal = async (vin) => {

        var auction_data = await getAuctionDetails(vin);
        console.log("auction.js getAuction", vin, auction_data);
        setActiveAuction(auction_data);        

        setOpenBidModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeBidModal = () => {
        setOpenBidModal(false);
        document.body.classList.remove('overflow-body');
    }

    const getAuction = () => {}

    // Chat Modal
    const [openChatModal, setOpenChatModal] = useState(false);
    const handleChatModal = () => {
        setOpenChatModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeChatModal = () => {
        setOpenChatModal(false);
        document.body.classList.remove('overflow-body');
    }
    
    return (
        <>
            {/* <div className={`register-to-bid-block ${expandAction ? 'open' : ''}`}>
                <div className="container">
                    <div className='d-md-none la-main-block' onClick={handleLiveAction}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='la-title'>Live Auctions</div>
                            <div><span className='rb-left-icon up-arrow-icon'></span></div>
                            <div className='rb-count pe-0'>&nbsp;</div>
                        </div>
                    </div>
                    {auctionData && <OwlCarousel
                        ref={carouselRef}
                        className="owl-theme rb-slider"
                        loop={true}
                        margin={25}
                        items={1}
                        dots={true}
                        nav={false}
                        {...optionsfl}
                    >
                        {auctionData.map((item, index) =>                         
                            <div className='item' key={index}>
                                <div className='d-md-none mob-rb-count rb-count pe-0' onClick={handleLiveAction}>{index + 1} of {auctionData?.length}</div>
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-8 col-xl-6 d-flex align-items-center justify-content-between pe-5">
                                        <div className='d-flex align-items-center'>
                                            <div className='rb-img'>
                                                {item.image_url_array && item.image_url_array.length > 0 ? <img src={item.image_url_array[0]}/> : <></>}
                                            </div>
                                            <div className='ps-4 text-start'>
                                                <div className="vdp-md-title fw-700 font-1-2em text-start">
                                                    <TextContainer text={item.year + " " + item.make + " " + item.model + " " + item.trim} width={220}/>
                                                </div>
                                                <div className='d-inline-flex align-items-center mt-2 mb-0 text-start'>
                                                    <div className="rb-xs-title pe-2">Time <span>Remaining</span></div>
                                                    <div className="xs-title fw-400"><Timer time_to_expire={item.time_to_expire} diff_seconds={item.diff_seconds} timerLayout={"footer"}></Timer></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-none d-md-block text-center'>
                                            <div className="rb-xs-title mb-0 mt-0">Current High Bid</div>
                                            <div className="vdp-md-title">
                                                {item && item.max_bid ? priceFormatter(item.max_bid, true) : "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xl-6 border-l ps-5 d-none d-lg-block">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-none d-xl-block'>
                                                <div className='d-flex align-items-center'>
                                                    <div>
                                                        <button className='rb-btn' type='button' onClick={() => carouselRef.current.prev()}><span className='rb-left-icon me-3'></span> Back</button>
                                                    </div>
                                                    <div className='rb-count'>{index + 1} of {auctionData?.length}</div>
                                                    <div>
                                                        <button className='rb-btn' type='button' onClick={() => carouselRef.current.next()}>Next <span className='rb-left-icon rb-right-icon ms-3'></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-end'>
                                                {user ? 
                                                    <button className='green-btn register-bid-btn text-uppercase' onClick={() => {handleBidModal(item.vin)}}>Bid</button>: 
                                                    <button className='green-btn register-bid-btn text-uppercase' onClick={handleLoginModal}>Register TO Bid</button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-md-none mob-bid-info pt-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex align-items-center mt-2'>
                                            <div className="rb-xs-title pe-3">Current High Bid:</div>
                                            <div className="vdp-md-title">
                                                {item && item.max_bid ? priceFormatter(item.max_bid, true) : "-"}
                                            </div>
                                        </div>
                                        <div className='text-end'>
                                            {user ? 
                                                <button className='green-btn register-bid-btn text-uppercase' onClick={() => {handleBidModal(item.vin)}}>Bid</button>: 
                                                <button className='green-btn register-bid-btn text-uppercase' onClick={handleLoginModal}>BID</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </OwlCarousel>}
                </div>
            </div> */}
            <footer className='footer-wrap'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='main-logo mb-3'>
                                <Link to="/">
                                    <img src={getImages('main-logo.svg')} alt='logo' />
                                </Link>
                            </div>
                            <p>Premier Community for Automotive Enthusiasts</p>
                            <div className='mt-5'>
                                <a href="#" className='me-4'>
                                    <img src={getImages('fb-icon.svg')} alt='fb' />
                                </a>
                                <a href="#" className='me-4'>
                                    <img src={getImages('insta-icon.svg')} alt='insta' />
                                </a>
                                <a href="#">
                                    <img src={getImages('tw-icon.svg')} alt='tw' />
                                </a>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex justify-content-end'>
                            <ul className='footer-menu w-240'>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <a href='#'>Vehicle Collection</a>
                                </li>
                                <li>
                                    <a href='#'>Membership Perks</a>
                                </li>
                                <li>
                                    <a href='#'>Events</a>
                                </li>
                                <li>
                                    <a href='#'>Venue</a>
                                </li>
                            </ul>
                            <ul className='footer-menu'>
                                <li>
                                    <a href='#'>Privacy Policy</a>
                                </li>
                                <li>
                                    <a href='#'>Terms of Service</a>
                                </li>
                                <li>
                                    <a href='#'>Copyright Information</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            
            {loginModal &&
                <ModalLayout open={loginModal} close={closeLoginModal} modalWidth={980}>
                    <LoginModal
                        close={closeLoginModal}
                        handleRegisterModal={handleRegisterModal}
                        handleForgotPwdModal={handleForgotPwdModal}
                    />
                </ModalLayout>
            }
            {registerModal &&
                <ModalLayout open={registerModal} close={closeRegisterModal} modalWidth={680}>
                    <RegisterModal close={closeRegisterModal} />
                </ModalLayout>
            }
            {forgotPwdModal &&
                <ModalLayout open={forgotPwdModal} close={closeForgotPwdModal} modalWidth={680}>
                    <ForgotPassword close={closeForgotPwdModal} />
                </ModalLayout>
            }
            {openBidModal &&
                <ModalLayout open={openBidModal} close={closeBidModal} modalWidth={970}>
                    <BidModal close={closeBidModal} handleChatModal={handleChatModal} auctionData={activeAuction} getAuction={getAuction}/>
                </ModalLayout>
            }
            {openChatModal &&
                <ModalLayout open={openChatModal} close={closeChatModal} modalWidth={1170}>
                    <ChatModal close={closeChatModal} />
                </ModalLayout>
            }
        </>
    )
}

export default Footer