import React, { useState, useEffect, useContext } from 'react'
import { getImages } from '../../Componment/const'
import { Link } from 'react-router-dom'
import ModalLayout from '../../Componment/Frontend/Common/ModalLayout'
import AuctionBidNowModal from '../../Componment/Frontend/Auction/AuctionBidNowModal'

import RegisterModal from '../../Componment/Frontend/Auction/RegisterModal'
import BidModal from '../../Componment/Frontend/Auction/BidModal'
import LoginModal from '../../Componment/Frontend/Auction/LoginModal'
import ForgotPassword from '../../Componment/Frontend/Auction/ForgotPassword'
import ChatModal from '../../Componment/Frontend/Auction/ChatModal'

import { AuctionContext } from '../../Componment/Context/AuctionContext';
import { VehicleContext } from '../../Componment/Context/VehicleContext';
import { UserContext } from '../../Componment/Context/UserContext'

import Timer from '../../Componment/Frontend/Common/Timer';


const AuctionsList = () => {
    const { getAuctionDetails, auctionData, getAuctionData } = useContext(AuctionContext);
    const { numberFormatter, priceFormatter } = useContext(VehicleContext);
    const { user } = useContext(UserContext);

    const [activeAuction,setActiveAuction] = useState([]);

    // Login Modal
    const [loginModal, setLoginModal] = useState(false);
    const handleLoginModal = () => {
        setLoginModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeLoginModal = () => {
        setLoginModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Register Modal
    const [registerModal, setRegisterModal] = useState(false);
    const handleRegisterModal = () => {
        setRegisterModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeRegisterModal = () => {
        setRegisterModal(false);
        document.body.classList.remove('overflow-body');
    }
    // ForgotPwd Modal
    const [forgotPwdModal, setForgotPwdModal] = useState(false);
    const handleForgotPwdModal = () => {
        setForgotPwdModal(true);
        setLoginModal(false);
        document.body.classList.add('overflow-body');
    }
    const closeForgotPwdModal = () => {
        setForgotPwdModal(false);
        document.body.classList.remove('overflow-body');
    }
    // Bid Modal
    const [openBidModal, setOpenBidModal] = useState(false);
    const handleBidModal = async (vin) => {

        var auction_data = await getAuctionDetails(vin);
        console.log("auction.js getAuction", vin, auction_data);
        setActiveAuction(auction_data);        

        setOpenBidModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeBidModal = () => {
        setOpenBidModal(false);
        document.body.classList.remove('overflow-body');
    }

    const getAuction = () => {}

    // Chat Modal
    const [openChatModal, setOpenChatModal] = useState(false);
    const handleChatModal = () => {
        setOpenChatModal(true);
        document.body.classList.add('overflow-body');
    }
    const closeChatModal = () => {
        setOpenChatModal(false);
        document.body.classList.remove('overflow-body');
    }

    useEffect(() => {
        getAuctionData();
    },[])
    
    return (
        <>
            <section className='la-banner-wrap py-5'>
                <div className='container'>
                    <div className='banner-title text-start mb-4 wow fadeInDown' data-wow-delay="0.2s">Live Auctions</div>
                    <div className='la-tagline-box d-flex align-items-center'>
                        <div className='lat-left d-flex align-items-center py-2 pe-5'>
                            <div className='lat-icon me-4'>
                                <img src={getImages('wifi_tethering.svg')}/>
                            </div>
                            <div>
                                <div className='lat-title md-title fw-900'>Live Auction</div>
                                <div className='lat-right text-uppercase d-md-none mt-1'>Place your bid before they’re gone</div>
                            </div>
                        </div>
                        <div className='lat-right ps-5 text-uppercase d-none d-md-block'>Place your bid before they’re gone</div>
                    </div>
                    <div className='la-list-block mb-100'>
                        {auctionData && auctionData.map((item, index) =>
                            (item.diff_seconds > 0 ? 
                            <div className='la-list-box d-flex align-items-center' key={index}>
                                <div className='lal-img'>
                                    <Link to={"/auction-details?vin=" + item.vin}>
                                        {item.image_url_array && item.image_url_array.length > 0 ? <img src={item.image_url_array[0]}/> : <></>}
                                    </Link>
                                </div>
                                <div className='lal-content'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <div className='lg-title fw-700 font-2em mb-3'>{item.year + " " + item.make}</div>
                                            <div className='md-title fw-700 mb-3'>{item.model + " " + item.trim}</div>
                                            <div className='xs-title font-1-1em fw-400 opacity-75'>Miles: {numberFormatter(item.miles)}</div>
                                        </div>
                                        <div>
                                            <Timer time_to_expire={item.time_to_expire} diff_seconds={item.diff_seconds} timerLayout={"srp"}></Timer>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-end justify-content-between mt-5 pt-2 la-bottom'>
                                        <div>
                                            <div className='lal-subtitle'>Current Bid Price</div>
                                            <div className='lg-title position-relative'><span className='bid-price-status red-arrow'></span>{item && item.max_bid ? priceFormatter(item.max_bid, true) : "-"}</div>
                                        </div>
                                        <div>                                            
                                            {user ? 
                                                <button type='button' className='green-btn lg-btn mb-2' onClick={() => {handleBidModal(item.vin)}}>Bid Now</button>: 
                                                <button type='button' className='green-btn lg-btn mb-2' onClick={handleLoginModal}>Bid Now</button>}
                                        </div>
                                    </div>
                                </div>
                            </div> : <div key={index}></div>)
                        )}
                    </div>
                    <div className='la-tagline-box d-flex align-items-center'>
                        <div className='lat-left d-flex align-items-center py-2 pe-5'>
                            <div className='lat-icon me-4'>
                                <img src={getImages('cart-icon.svg')}/>
                            </div>
                            <div>
                                <div className='lat-title md-title fw-900'>Just Ended</div>
                                <div className='lat-right text-uppercase d-md-none mt-1'>Last chance to place a bid</div>
                            </div>
                        </div>
                        <div className='lat-right ps-5 text-uppercase d-none d-md-block'>Last chance to place a bid</div>
                    </div>
                    {auctionData && auctionData.map((item, index) =>
                            (item.diff_seconds <= 0 ? 
                            <div className='la-list-box d-flex align-items-center' key={index}>
                                <div className='lal-img'>
                                    <Link to={"/auction-details?vin=" + item.vin}>
                                        {item.image_url_array && item.image_url_array.length > 0 ? <img src={item.image_url_array[0]}/> : <></>}
                                    </Link>
                                </div>
                                <div className='lal-content'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <div className='lg-title fw-700 font-2em mb-3'>{item.year + " " + item.make}</div>
                                            <div className='md-title fw-700 mb-3'>{item.model + " " + item.trim}</div>
                                            <div className='xs-title font-1-1em fw-400 opacity-75'>Miles: {numberFormatter(item.miles)}</div>
                                        </div>
                                        <div>
                                        <div className='xs-title font-1-1em fw-700'>
                                            <span className='clock-icon me-3'></span> 
                                            <span className="text-red">0d</span>&nbsp;
                                            <span className="text-red">0h</span>&nbsp;
                                            <span className="text-red">0m</span>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-end justify-content-between mt-5 pt-2 la-bottom'>
                                        <div>
                                            <div className='lal-subtitle'>Current Bid Price</div>
                                            <div className='lg-title position-relative'><span className='bid-price-status red-arrow'></span>{item && item.max_bid ? priceFormatter(item.max_bid, true) : "-"}</div>
                                        </div>
                                        <div>
                                            {user ? 
                                                <button type='button' className='green-btn lg-btn mb-2' onClick={() => {handleBidModal(item.vin)}}>Bid Now</button>: 
                                                <button type='button' className='green-btn lg-btn mb-2' onClick={handleLoginModal}>Bid Now</button>}
                                        </div>
                                    </div>
                                </div>
                            </div> : <div key={index}></div>)
                        )}
                </div>
            </section>
            {loginModal &&
                <ModalLayout open={loginModal} close={closeLoginModal} modalWidth={980}>
                    <LoginModal
                        close={closeLoginModal}
                        handleRegisterModal={handleRegisterModal}
                        handleForgotPwdModal={handleForgotPwdModal}
                    />
                </ModalLayout>
            }
            {registerModal &&
                <ModalLayout open={registerModal} close={closeRegisterModal} modalWidth={680}>
                    <RegisterModal close={closeRegisterModal} />
                </ModalLayout>
            }
            {forgotPwdModal &&
                <ModalLayout open={forgotPwdModal} close={closeForgotPwdModal} modalWidth={680}>
                    <ForgotPassword close={closeForgotPwdModal} />
                </ModalLayout>
            }
            {openBidModal &&
                <ModalLayout open={openBidModal} close={closeBidModal} modalWidth={970}>
                    <BidModal close={closeBidModal} handleChatModal={handleChatModal} auctionData={activeAuction} getAuction={getAuction}/>
                </ModalLayout>
            }
            {openChatModal &&
                <ModalLayout open={openChatModal} close={closeChatModal} modalWidth={1170}>
                    <ChatModal close={closeChatModal} />
                </ModalLayout>
            }
        </>
    )
}

export default AuctionsList