import React from 'react'

const AskDealerQuestion = ({ close }) => {
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title text-uppercase">
                        Ask Dealer A Question
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body px-4 py-4">
                <div className='register-about text-center mb-3'>Please enter your question below and you will receive a response directly from our dealership:</div>
                    <form className="service-from form-horizontal">
                        <div className="form-group mb-3">
                            <label className="control-label">FIRST NAME</label>
                            <div className="">
                                <input type="text" className="form-control" name="first_name" autoComplete="new-password" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label">LAST NAME</label>
                            <div className="">
                                <input type="text" className="form-control" name="last_name" autoComplete="new-password" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label">Email</label>
                            <div className="">
                                <input type="email" className="form-control" name="email" autoComplete="new-password"  />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label className="control-label">QUESTION</label>
                            <div className="">
                                <textarea className="form-control h-120" rows={5}></textarea>
                            </div>
                        </div>
                        <div className="register-btn mt-4">
                            <button type="submit" className="green-btn w-100 lg-btn">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
  )
}

export default AskDealerQuestion