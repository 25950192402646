import React, { useState, useContext, useEffect } from 'react'
import { getImages } from '../../const'
import { AuctionContext } from '../../Context/AuctionContext';
import { UserContext } from '../../Context/UserContext';
import { VehicleContext } from '../../Context/VehicleContext';

import Timer from '../Common/Timer';

const BidModal = ({ close, handleChatModal, auctionData, getAuction }) => {


    const {submitBid,buyItNow} = useContext(AuctionContext);
    const {user} = useContext(UserContext);
    
    const { numberFormatter } = useContext(VehicleContext);

    const [currentBid,setCurrentBid] = useState(0);


    useEffect(() => {
        if(auctionData) setCurrentBid(auctionData.max_bid + (auctionData.bid_increment ? auctionData.bid_increment : 1));
    },[auctionData]);

    const buyItNowButton = async (buy_it_now) => {
        setCurrentBid(buy_it_now);

        const data = await buyItNow(auctionData.vin,user.username,buy_it_now);

        if(data && data.success) {            
            setTimeout(() => {
                getAuction(auctionData.vin);
            }, 2000);
            alert("Bid successful");
        } else if(data && data.error) alert(data.error);
    }

    const placeBid = async () => {
        const data = await submitBid(auctionData.vin,user.username,currentBid);

        if(data && data.success) {            
            setTimeout(() => {
                getAuction(auctionData.vin);
            }, 2000);
            alert("Bid successful");
        } else if(data && data.error) alert(data.error);
    }

    
    return (
        <>
        {
            console.log("auctionData", auctionData)
        }
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title">
                        LIVE AUCTION
                    </h1>
                    <button className="sm-box-close" type="button" onClick={close}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body px-4 py-4 bid-modal-body">
                    <div className="d-flex align-items-center mb-3 live-auction-flex">
                        <div className="live-auction-left w-50 d-flex align-items-center">
                            <div className='gavel-icon me-2'><img src={getImages('icon-gavel-white@2x.webp')} /></div>
                            <div className='xs-title font-1-3em fw-700 text-start'>Time Remaining</div>
                        </div>
                        {auctionData ? <Timer time_to_expire={auctionData.time_to_expire} diff_seconds={auctionData.diff_seconds} timerLayout={"bidmodal"}/> : <></>}
                    </div>
                    <div className="row">
                        <div className="col-md-6 pe-4">
                            <div className="sm-title font-1-3em text-black fw-700">Vehicle Info</div>
                            <div className="sm-title font-1-1em text-black fw-700 mt-2">{auctionData.year + " " + auctionData.make + " " + auctionData.model + " " + auctionData.trim}</div>
                            <div className="vehicle-options mt-3 mb-2">
                                <table className='w-100'>
                                    <tbody>
                                        <tr>
                                            <th>Vehicle Type:</th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>VIN:</th>
                                            <td>{auctionData ? auctionData.vin : "WP0AA2A98HS106979"}</td>
                                        </tr>
                                        <tr>
                                            <th>Miles:</th>
                                            <td>{numberFormatter(auctionData ? auctionData.miles : 22000)}</td>
                                        </tr>
                                        <tr>
                                            <th>Color:</th>
                                            <td>{auctionData ? auctionData.color + " / " + auctionData.int_color : "Red / Black"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="note-text mb-3">
                                CARFAX Issues: {auctionData ? auctionData.carfax_issues : "no"} | Body Style: {auctionData ? auctionData.body_type : "Coupe"} | Full Set of Keys: {auctionData ? (auctionData.keys ? "yes" : "no"): "yes"} | Books &amp; Manuals: {auctionData ? (auctionData.book_manual ? "yes" : "no"): "yes"}  | MSRP Known: Yes  | Tires Front: {auctionData ? auctionData.tires_front : "good"} | Tires Rear: {auctionData ? auctionData.tires_back : "good"}  | Title: {auctionData ? auctionData.title_status : "good"}
                            </div>
                            <div className="contacts">
                                <div>
                                    <button className="transparent-green-btn md-btn" type='button' onClick={handleChatModal}>
                                        <img src={getImages('icon-chat.svg')} className="me-2" /> CHAT WITH SELLER
                                        {/* <span>2</span> */}
                                    </button>
                                </div>
                                <div className="vehicle-options mt-3 mb-2">
                                    <table className='w-100'>
                                        <tbody>
                                            <tr>
                                                <td>Contact Seller</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>{auctionData ? auctionData.agent_dealership_info.phone : "917-224-7474"}</td>
                                                <td className='text-end'><a href={"mailto:" + (auctionData ? auctionData.agent_dealership_info.email : "vsidorenko@ferrarili.com")} className="text-green">{auctionData ? auctionData.agent_dealership_info.email : "vsidorenko@ferrarili.com"}</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ps-4 border-l">
                            <div className=''>
                                <ul className="nav nav-tabs" id="mTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="standard-tab" data-bs-toggle="tab" data-bs-target="#standard-tab-pane" type="button" role="tab" aria-controls="standard-tab-pane" aria-selected="true">Standard</button>
                                    </li>
                                    {/*<li className="nav-item" role="presentation">
                                        <button className="nav-link" id="Proxy-Bid-tab" data-bs-toggle="tab" data-bs-target="#Proxy-Bid-tab-pane" type="button" role="tab" aria-controls="Proxy-Bid-tab-pane" aria-selected="false">Proxy Bid</button>
                                    </li>*/}
                                </ul>
                                <div className="tab-content" id="mTabContent">
                                    <div className="tab-pane fade show active" id="standard-tab-pane" role="tabpanel" aria-labelledby="standard-tab" tabIndex="0">
                                        <div className='mb-3'>
                                            <div className="vehicle-options">
                                                <table className='w-100'>
                                                    <tbody>
                                                        <tr>
                                                            <th>Opening Bid:</th>
                                                            <td>{auctionData && auctionData.starting_price ? numberFormatter(auctionData.starting_price, true) : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Your last Bid:</th>
                                                            <td>{auctionData && auctionData.your_bid ? numberFormatter(auctionData.your_bid, true) : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Current High Bid:</th>
                                                            <td>{auctionData && auctionData.max_bid ? numberFormatter(auctionData.max_bid, true) : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Bid Increment Min:</th>
                                                            <td>{auctionData && auctionData.bid_increment ? numberFormatter(auctionData.bid_increment, true) : "-"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='row service-form'>
                                            <div className='col-md-6 col-12 mb-3'>
                                                <input type='text' className='form-control w-100 fw-700 font-1-3em' value={currentBid} onChange={(e) => {setCurrentBid(e.target.value)}}/>
                                            </div>
                                            <div className='col-md-6 col-12 mb-3'>
                                                <button className='green-btn w-100 place-bid-btn' onClick={placeBid}>Place Bid</button>
                                            </div>
                                        </div>
                                        <div className="reverse d-flex mb-3 align-items-center">
                                            <span className="tooltip-btn me-1" data-toggle="tooltip">
                                                <i className="text-green fa fa-info-circle green font-16"></i>
                                                <div className="tooltip-hover">
                                                    <b className="black">Reserve</b> - The minimum bid required to sell the car. Opening Bid is required to be at least 85% of Reserve Price.
                                                    <br /><br />
                                                    <b className="black">Reserve:</b> <b className="red">Not Met</b> - The minimum bid has not been met, seller has option not to sell the vehicle.
                                                    <br /><br />
                                                    <b className="black">Reserve:</b> <b className="green">Met</b> - The minimum bid has been met &amp; this vehicle will sell!
                                                </div>
                                            </span>
                                            <div className='me-1'>Reserve:</div>
                                            <div className="text-green">Not Met</div>
                                        </div>
                                        <hr />
                                        <div className='row align-items-center'>
                                            <div className='col-md-6 col-12 mb-3'>
                                                <div className='sm-title text-black fw-600 text-center mb-2'>Buy it Now Price:</div>
                                                <div className='md-title text-black fw-900 text-center'>{auctionData && auctionData.buy_it_now ? numberFormatter(auctionData.buy_it_now, true) : "-"}</div>
                                            </div>
                                            <div className='col-md-6 col-12 mb-3'>
                                                <button className='green-btn w-100 place-bid-btn text-uppercase' onClick={() => {buyItNowButton(auctionData.buy_it_now);}}>Buy It Now</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Proxy-Bid-tab-pane" role="tabpanel" aria-labelledby="Proxy-Bid-tab" tabIndex="0">
                                        <div className='mb-3'>
                                            <div className="vehicle-options">
                                                <table className='w-100'>
                                                    <tbody>
                                                        <tr>
                                                            <th>Opening Bid:</th>
                                                            <td>$0</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Your last Bid:</th>
                                                            <td>$0</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Current High Bid:</th>
                                                            <td>$188,480</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Bid Increment Min:</th>
                                                            <td>$1,000</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='row mb-3 service-form'>
                                            <div className='col-md-6 col-12 mb-3'>
                                                <input type='text' className='form-control w-100 fw-700 font-1-3em' />
                                            </div>
                                            <div className='col-md-6 col-12 mb-3'>
                                                <button className='green-btn w-100 place-bid-btn'>Place Bid</button>
                                            </div>
                                        </div>
                                        <div className="reverse d-flex align-items-center">
                                            <span className="tooltip-btn me-1" data-toggle="tooltip">
                                                <i className="text-green fa fa-info-circle green font-16"></i>
                                                <div className="tooltip-hover">
                                                    <b className="black">Reserve</b> - The minimum bid required to sell the car. Opening Bid is required to be at least 85% of Reserve Price.
                                                    <br /><br />
                                                    <b className="black">Reserve:</b> <b className="red">Not Met</b> - The minimum bid has not been met, seller has option not to sell the vehicle.
                                                    <br /><br />
                                                    <b className="black">Reserve:</b> <b className="green">Met</b> - The minimum bid has been met &amp; this vehicle will sell!
                                                </div>
                                            </span>
                                            <div className='me-1'>Reserve:</div>
                                            <div className="text-green">Not Met</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BidModal