import React, { Suspense } from 'react'
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./assets/css/all.min.css";
import './assets/css/chat.css'
import './assets/css/animate.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

const Layout  = React.lazy(() =>  import('./Componment/Frontend/Layout'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Layout>
          <AppRoutes />
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
