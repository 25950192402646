import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const cleanBrTags = (htmlString) => {
    // Replace multiple <br> tags with a single space
    return htmlString ? htmlString.replace(/(<br\s*\/?>\s*){2,}/g, ' ') : "";
};

const ReadMore = ({ text, isExpanded, onToggle, sectionId, readMoreUrl, maxChars }) => {
    const navigate = useNavigate();
    const handleReadMoreClick = (event) => {
        event.preventDefault();
        navigate(`${readMoreUrl}#${sectionId}`);
        setTimeout(() => {
            window.history.replaceState(null, '', readMoreUrl);
        }, 100);
    };
    
    const cleanedText = cleanBrTags(text);
    const truncatedText = cleanedText.length > maxChars ? cleanedText.slice(0, maxChars) + '...' : cleanedText;

    return (
        <div className='vehicle-desc'>
            <p className={`content-container ${isExpanded ? '' : 'truncated-text'}`}>
                <span 
                    dangerouslySetInnerHTML={{ __html: isExpanded ? cleanedText : truncatedText }} 
                />
                {!isExpanded && (
                    <a 
                        href={`${readMoreUrl}#${sectionId}`} 
                        onClick={handleReadMoreClick} 
                        className='read-more-link text-green'
                    >
                        Read More
                    </a>
                )}
            </p>
        </div>
    );
};

export default ReadMore;
