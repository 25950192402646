import React, { useContext, useState } from 'react'
import { getImages } from '../../Componment/const'
import { Field, Form, Formik } from 'formik';
import ValidationError from '../../Componment/Errors/ValidationError';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { VehicleContext } from '../../Componment/Context/VehicleContext';

const FerrariService = () => {
    const {submitContactForm} = useContext(VehicleContext);

    const scrollToSection = () => {
      const section = document.getElementById('ScheduleAppointment');
      section.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToPrice = () => {
      const section = document.getElementById('vintagePrice');
      section.scrollIntoView({ behavior: 'smooth' });
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <>
            <section className='ferrari-service-banner' style={{
                backgroundImage: `#161616 url(${getImages('ferrari-service-banner.webp')}) no-repeat center top`,
                backgroundSize: 'cover'
            }}>
                <div className='text-center'>
                    <div className='mb-4 ferrari-logo'>
                        <img src={getImages('ferrari-logo.svg')} alt='ferrari' />
                    </div>
                    <div className='banner-title'>Ferrari Vintage Service</div>
                </div>
            </section>
            <section className='btns-group text-center'>
                <div className='container'>
                    <div className='text-center'>
                        <div>
                            <button className='green-btn lg-btn mob-lg-btn' type='button' onClick={scrollToSection}>Schedule Service</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about-service-wrap'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-12 pe-5'>
                            <div className='d-flex mb-5 ferrari-logo-mix'>
                                <img className='me-3' src={getImages('ferrari-icon-1.svg')} />
                                <img src={getImages('ferrari-icon-2.svg')} />
                            </div>
                            <div className='main-heading mb-4 font-2em'>HISTORY ONLY HAPPENS ONCE; IT IS <br />OUR DUTY TO MAINTAIN ITS GLORY.</div>
                            <div className='content'>
                                <p>Welcome to The Kent Motor Club Service Department. For 15 years our team kicked ass and took names at a franchised Ferrari-Maserati dealership and now we’ve taken our talents to Brooklyn. Our crew has both Ferrari and Maserati Master Technician Certifications. At Kent Motor Club, we combine a passion for Ferrari’s rich history and craftsmanship with unmatched technical expertise to deliver the best damn work at the best damn price.
                                </p>
                                <p>We invest in the latest technologies and are equipped with the proper tools to handle your car right and whether it's routine maintenance, complex 
                                {isExpanded ? (
                                    <span> diagnostics, engine out services, performance enhancements or anything else- we only take on work that we know we can do right. If you are looking for a run of the mill, experience with no passion or character behind it- look elsewhere. We accept all forms of payment, including all domestic beers and top shelf liquor.
                                    </span>
                                ) : '...'}
                                <span className='text-green' onClick={toggleReadMore} style={{ cursor: 'pointer' }}>
                                    {isExpanded ? ' Read less' : ' Read more'}
                                </span>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="vdpa-box p-4 mb-4 vdpa-info-box">
                                <div className='vdpa-modal-body'>
                                    <div className='md-title mb-4 wow fadeInUp' data-wow-delay="0.2s">Service Info</div>
                                    <div className='vdpa-table service-info-table'>
                                        <div className='text-start text-green md-title font-1-3em'>Phone Number:</div>
                                        <table className='w-100 mt-2'>
                                            <tbody>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Service</th>
                                                    <td>800-123-4567</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='text-start text-green md-title font-1-3em mt-5'>Service Hours:</div>
                                        <table className='w-100 mt-2'>
                                            <tbody>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Mon - Fri</th>
                                                    <td>8:00 AM - 6:00 PM</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Saturday</th>
                                                    <td>10:00 AM - 5:00 PM</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Sunday</th>
                                                    <td>Closed</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Thanksgiving Day</th>
                                                    <td>Closed</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Christmas Day</th>
                                                    <td>Closed</td>
                                                </tr>
                                                <tr className='wow fadeInUp' data-wow-delay="0.2s">
                                                    <th>Independence Day</th>
                                                    <td>Closed</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='vintage-services-wrap py-5'>
                <div className='container'>
                    <div className='main-heading mb-5' id='vintagePrice'>Vintage Services</div>
                    <div className='vdpa-box  vdpa-box-service'>
                        <div className="vintage-table">
                            <div className='vt-header'>
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            <th>Model</th>
                                            <th>Oil</th>
                                            <th>Annual</th>
                                            <th>Major</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className='scroll-y'>
                                <table className="w-100">
                                    <tbody>
                                        <tr>
                                            <td>308</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>328</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>MONDIAL</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>348</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>MONDIAL T</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>355</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>512</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>TESTAROSSA</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>360</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                        <tr>
                                            <td>456</td>
                                            <td>$359.58</td>
                                            <td>$895.29</td>
                                            <td>$5,636.87</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='car-modals-wrap py-5'>
                <div className='container'>
                    <div className='md-title font-1-5em mb-5'>SPECIAL PRICING ON ALL SERVICES FOR THE FOLLOWING MODELS:</div>
                    <div className='row g-4'>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img1.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 308</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img2.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 328</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img3.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 348</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img4.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI MONDIAL</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img5.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 550</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img6.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 575</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img7.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI F355</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img8.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI MONDIAL T</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img9.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 456</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img10.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 612</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img11.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI TESTAROSSA</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-6'>
                            <div className='car-modal-box d-flex align-items-center' onClick={scrollToPrice}>
                                <div className='w-100 text-center'>
                                    <div className='car-modal-img mt-3 mb-4 pb-2'>
                                        <img src={getImages('car-modal-img12.webp')} alt='car modal' />
                                    </div>
                                    <div className='md-title'>FERRARI 360</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5 pt-3 text-center'>
                        <button className="green-btn lg-btn mob-lg-btn" type="button">Schedule Service</button>
                    </div>
                </div>
            </section>
            <section className='service-perks-wrap pt-4'>
                <div className='container'>
                    <div className='main-heading mb-5'>Ferrari Service Perks</div>
                    <div className='row g-4'>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon1.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Factory Trained Technicians </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon2.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Complementary Multi-Point Inspection Report</div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon3.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Ferrari Genuine Parts</div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon4.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Competitive <br />Pricing</div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon5.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Ferrari Owner’s Piece of Mind</div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon6.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Personal <br />Transport</div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon7.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Luxury Car wash prior to delivery</div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12'>
                            <div className='perk-box d-flex align-items-center'>
                                <div className='perk-icon'>
                                    <img src={getImages('perk-icon8.svg')} alt='car modal' />
                                </div>
                                <div className='sm-title fw-400'>Satisfaction Guaranteed</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='address-direction-wrap py-5'>
                <div className='container'>
                    <div className='md-title font-1-5em mb-4 pb-2 text-center'>Kent Motor Club is located 22-11 38th Ave Long Island City, NY 11101 </div>
                    <div className='d-flex align-items-center mobile-app-form ad-block'>
                        <input type='text' className='form-control me-20' placeholder='Enter your address for directions' />
                        <button className='green-btn' type='button'>Go</button>
                    </div>
                </div>
            </section>

            <section className='contact-wrap py-100'>
                <div className='container'>
                    <div className='gray-box' id='ScheduleAppointment'>
                        <div className='main-heading text-center text-uppercase mb-3 wow fadeInUp' data-wow-delay="0.2s">Schedule your Service</div>
                        <div className='md-title fw-400 pb-5 text-center wow fadeInDown' data-wow-delay="0.4s">Call or Text us at 516-345-6789</div>
                        <Formik
                            // validationSchema={validationSchema}
                            initialValues={{
                                vehicle: '',
                                full_name: '',
                                email: '',
                                phone: '',
                                preferable_date: '',
                                preferable_time: '',
                                requested_services: '',
                            }}
                            onSubmit={(values) => {
                                console.log(values);
                                submitContactForm(values);
                                alert("Thank you for your submission");
                            }}
                        >
                            {({ values, setFieldValue, field, form }) => (
                                <Form className="service-from" autoComplete="off">
                                    <div className='row mx-50'>
                                        <div className='col-md-6 px-50 wow fadeInLeft' data-wow-delay="0.4s">
                                            <div className='row'>
                                                <div className='form-group col-12'>
                                                    <div className='cs-label'>Vehicle</div>
                                                    <Field
                                                        type="text"
                                                        name="vehicle"
                                                        className="form-control"
                                                        placeholder="Enter Year Make Model Trim"
                                                    />
                                                    <ValidationError name="vehicle" />
                                                </div>
                                                <div className='form-group col-12'>
                                                    <div className='cs-label'>Full Name</div>
                                                    <Field
                                                        type="text"
                                                        name="full_name"
                                                        className="form-control"
                                                    />
                                                    <ValidationError name="full_name" />
                                                </div>
                                                <div className='form-group col-12'>
                                                    <div className='cs-label mb-2'>Email</div>
                                                    <Field
                                                        type="text"
                                                        name="email"
                                                        className="form-control"
                                                    />
                                                    <ValidationError name="email" />
                                                </div>
                                                <div className='form-group col-12'>
                                                    <div className='cs-label mb-2'>Phone</div>
                                                    <Field
                                                        type="text"
                                                        name="phone"
                                                        className="form-control"
                                                    />
                                                    <ValidationError name="phone" />
                                                </div>
                                                <div className='form-group col-6'>
                                                    <div className='cs-label'>Preferable Date</div>
                                                    <Field name="preferable_date" className="w-100">
                                                        {({ form, field }) => (
                                                            <DatePicker
                                                                className="form-control w-100 calendar-field"
                                                                id="date"
                                                                {...field}
                                                                selected={field.value}
                                                                onChange={(date) => form.setFieldValue(field.name, date)}
                                                                dateFormat="MM/dd/yyyy"
                                                            />
                                                        )}
                                                    </Field>
                                                    <ValidationError name="preferable_date" />
                                                </div>
                                                <div className='form-group col-6'>
                                                    <div className='cs-label'>Preferable Time</div>
                                                    <Field
                                                        type="text"
                                                        name="preferable_time"
                                                        className="form-control time-field"
                                                    />
                                                    {/* <Field name="preferable_time">
                                                    {({ form, field }) => (
                                                        <TimePicker
                                                        className="form-control"
                                                        id="time"
                                                        {...field}
                                                        onChange={(time) => form.setFieldValue(field.name, time)} 
                                                        value={field.value}
                                                        format="HH:mm"
                                                        disableClock={true}
                                                        />
                                                    )}
                                                    </Field> */}
                                                    <ValidationError name="preferable_time" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 px-50 wow fadeInRight' data-wow-delay="0.6s">
                                            <div className='form-group'>
                                                <div className='cs-label'>Requested Services</div>
                                                <Field
                                                    as="textarea"
                                                    name="requested_services"
                                                    className="form-control h-370"
                                                />
                                                <ValidationError name="requested_services" />
                                            </div>
                                            <div className='mt-4 text-end'>
                                                <button type='submit' className='green-btn lg-btn w-300'>Send</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FerrariService